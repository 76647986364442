import {LOAD_PURCHASED_PRODUCTS} from '../actions/actionNames'
import {getFromLocal, storeOnLocal} from '../learning_platform/utils/localStorage'
import {PURCHASED_PRODUCTS} from '../global_constants/fixed_variable_values'

const initialState = getFromLocal(PURCHASED_PRODUCTS) ?? null

const purchasedProducts = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PURCHASED_PRODUCTS:
            const {purchasedProducts} = action.payload
            storeOnLocal(PURCHASED_PRODUCTS, purchasedProducts)
            return purchasedProducts

        default:
            return state
    }
}

export default purchasedProducts
