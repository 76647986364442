// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import useJwt from "../../../../auth/jwt/useJwt"
//const http:localhost:8
import jwtDefaultConfig from "../../../../@core/auth/jwt/jwtDefaultConfig"

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
    const response = await axios.get('/api/users/list/all-data')
    return response.data
})
export const getCountries = createAsyncThunk('appUsers/getCountries', async () => {
    //const res = await useJwt.apiCall('https://prod-tedd-auth.azurewebsites.net/vectra/region/getCountries').then(
    const res = await useJwt.apiCall(`${jwtDefaultConfig.authBaseUrl}/vectra/region/getCountries`).then(
        (data) => data.data.countryList
    )
    return res
})
export const getData = createAsyncThunk('appUsers/getData', async params => {
    const response = await axios.get('/api/users/list/data', params)
    return {
        params,
        data: response.data.users,
        totalPages: response.data.total
    }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
    const response = await axios.get('/api/users/user', {id})
    return response.data.user
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, {dispatch, getState}) => {
    await axios.post('/apps/users/add-user', user)
    await dispatch(getData(getState().users.params))
    await dispatch(getAllData())
    return user
})
export const updateUser = createAsyncThunk('appUsers/updateUser', async (data) => {
    //const res = await useJwt.apiCall('https://prod-tedd-auth.azurewebsites.net/vectra/user/updateInformation', data).then(
    const res = await useJwt.apiCall(`${jwtDefaultConfig.authBaseUrl}/vectra/user/updateInformation`, data).then(
        (data) => data.data
    )
    return res
})
export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, {dispatch, getState}) => {
    await axios.delete('/apps/users/delete', {id})
    await dispatch(getData(getState().users.params))
    await dispatch(getAllData())
    return id
})

export const appUsersSlice = createSlice({
    name: 'appUsers',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        selectedUser: null,
        countries: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getAllData.fulfilled, (state, action) => {
                state.allData = action.payload
            })
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.selectedUser = action.payload
            })
            .addCase(getCountries.fulfilled, (state, action) => {
                state.countries = action.payload
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.selectedUser = action.payload
            })
        // .addCase(getCustomerPaymentMethods.fulfilled, (state, action) => {
        //     state.paymentMethods = action.payload
        // })
        // .addCase(getExistingCustomerIdByEmail.fulfilled, (state, action) => {
        //     state.customerId = action.payload
        // })
    }
})

export default appUsersSlice.reducer
