// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import jwtDefaultConfig from '../../../../@core/auth/jwt/jwtDefaultConfig'
// ** Axios Imports
import axios from 'axios'

export const getWishlistItems = createAsyncThunk('appEcommerce/getWishlistItems', async () => {
    const response = await axios.get('/apps/ecommerce/wishlist')
    return response.data
})

export const deleteWishlistItem = createAsyncThunk('appEcommerce/deleteWishlistItem', async (id, {dispatch}) => {
    const response = await axios.delete(`/apps/ecommerce/wishlist/${id}`)
    dispatch(getWishlistItems())
    return response.data
})

export const getCartItems = createAsyncThunk('appEcommerce/getCartItems', async cuuid => {
    //  const response = await axios.get('/apps/ecommerce/cart')
    const response = await axios.get(`${jwtDefaultConfig.coreBaseUrl}/academy/getCart?cuuid=${cuuid}`)

    return response.data
})
export const getDiscount = createAsyncThunk('appEcommerce/getDiscountForCart', async cuuid => {
    //  const response = await axios.get('/apps/ecommerce/cart')

    const response = await axios.get(`${jwtDefaultConfig.coreBaseUrl}/academy/getDiscountForCart?cuuid=${cuuid}`)
    return response.data
})

export const getCartItemsForCheckOut = createAsyncThunk('appEcommerce/getCartItemsForCheckOut', async cuuid => {
    //  const response = await axios.get('/apps/ecommerce/cart')

    const response = await axios.get(`${jwtDefaultConfig.coreBaseUrl}/academy/getCart?cuuid=${cuuid}`)

    return response.data
})

export const getProducts = createAsyncThunk('appEcommerce/getProducts', async params => {
    // const response = await axios.get('/apps/ecommerce/products', { params })
    // return { params, data: response.data }
    const response = await axios.get(`${jwtDefaultConfig.coreBaseUrl}/academy/getActiveMarketProducts?cuuid=${params}`)

    return {params, data: response.data}
})

export const getConsultancyServices = createAsyncThunk('appEcommerce/getConsultancyServices', async params => {
    const response = await axios.get(`${jwtDefaultConfig.coreBaseUrl}/services/getActiveServices?region=${params}`)
    return {params, data: response.data}
})

export const setConsultancyServices = createAsyncThunk('appEcommerce/setConsultancyServices', async params => {
    const {consultancyServices, range, brand, type, categories, industries, tags} = params

    return consultancyServices.filter(
        item =>
            (brand.length === 0 ? true : brand.includes(item.status)) &&
            (type.length === 0 ? true : type.includes(item.type)) &&
            (categories.length === 0 ? true : categories?.every(category => item?.categories?.includes(category))) &&
            (industries.length === 0 ? true : industries?.every(industry => item?.industries?.includes(industry))) &&
            (tags.length === 0 ? true : tags?.every(tag => item?.tags?.includes(tag)))
    )
})
export const setProducts = createAsyncThunk('appEcommerce/setProducts', async params => {
    const {products, range, brand, type, categories, industries, tags} = params

    return products.filter(
        item =>
            item.priceAmount >= parseFloat(range[0]) &&
            item.priceAmount <= parseFloat(range[1]) &&
            (brand.length === 0 ? true : brand.includes(item.status)) &&
            (type.length === 0 ? true : type.includes(item.type)) &&
            (categories.length === 0 ? true : categories?.every(category => item?.categories?.includes(category))) &&
            (industries.length === 0 ? true : industries?.every(industry => item?.industries?.includes(industry))) &&
            (tags.length === 0 ? true : tags?.every(tag => item?.tags?.includes(tag)))
    )
})

export const getGlobalProducts = createAsyncThunk('appEcommerce/getGlobalProducts', async params => {
    // const response = await axios.get('/apps/ecommerce/products', { params })
    // return { params, data: response.data }${jwtDefaultConfig.coreBaseUrl}
    const response = await axios.get(`${jwtDefaultConfig.coreBaseUrl}/academy/getActiveGlobalProducts`)

    return {params, data: response.data}
})

export const getGlobalTools = createAsyncThunk('appEcommerce/getGlobalTools', async params => {
    // const response = await axios.get('/apps/ecommerce/products', { params })
    // return { params, data: response.data } ${jwtDefaultConfig.coreBaseUrl}
    const response = await axios.get(`${jwtDefaultConfig.coreBaseUrl}/academy/getActiveGlobalTools`)

    return {params, data: response.data}
})

export const deleteCartItem = createAsyncThunk('appEcommerce/deleteCartItem', async (delCart, {dispatch}) => {
    //await axios.delete(`/apps/ecommerce/cart/${id}`)

    await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/academy/removeProductFromCart?uuid=${delCart.idVal}&cuuid=${delCart.cuuid}`
    )

    dispatch(getCartItems(delCart.cuuid))

    dispatch(getProducts(delCart.cuuid))
    return id
})

export const addToCart = createAsyncThunk('appEcommerce/addToCart', async (addCart, {dispatch, getState}) => {
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/academy/addToCart?uuid=${addCart.idVal}&cuuid=${addCart.cuuid}`
    )
    //const response = await axios.post('/apps/ecommerce/cart', { productId: id })

    await dispatch(getProducts(getState().ecommerce.params))
    await dispatch(getCartItems(addCart.cuuid))
    return response.data
})

export const getProduct = createAsyncThunk('appEcommerce/getProduct', async product => {
    // const response = await axios.get(`/apps/ecommerce/products/${productId}`)
    // return response.data
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/academy/getProductDetails?uuid=${product.idVal}&cuuid=${product.cuuid}`
    )
    return response.data
})

export const getGlobalProduct = createAsyncThunk('appEcommerce/getGlobalProduct', async product => {
    // const response = await axios.get(`/apps/ecommerce/products/${productId}`)
    // return response.data
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/academy/getGlobalProductDetails?uuid=${product.idVal}`
    )

    return response.data
})

export const getConsultancyService = createAsyncThunk('appEcommerce/getConsultancyService', async product => {
    // const response = await axios.get(`/apps/ecommerce/products/${productId}`)
    // return response.data

    const response = await axios.get(`${jwtDefaultConfig.coreBaseUrl}/services/getServiceDetail?uuid=${product.idVal}`)

    return response.data
})
export const updateCartRegion = createAsyncThunk('appEcommerce/updateCartRegion', async cartUpdate => {
    // const response = await axios.get(`/apps/ecommerce/products/${productId}`)
    // return response.data,
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/academy/updateCartRegion?cuuid=${cartUpdate.cartId}&region=${cartUpdate.regionName}`
    )
    return response.data
})

export const addToWishlist = createAsyncThunk('appEcommerce/addToWishlist', async id => {
    await axios.post('/apps/ecommerce/wishlist', {productId: id})
    return id
})

export const addFreeProduct = createAsyncThunk('appEcommerce/addFreeProduct', async (product, {dispatch, getState}) => {
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/academy/addFreeProduct?cuuid=${product.cuuid}&uuid=${product.idVal}`
    )
    await dispatch(getProducts(getState().ecommerce.params))
    await dispatch(getCartItems(product.cuuid))
    return response.data
})

// export const addToCart = createAsyncThunk('appEcommerce/addToCart', async (addCart, {dispatch, getState}) => {
//     const response = await axios.get(
//         `${jwtDefaultConfig.coreBaseUrl}/academy/addToCart?uuid=${addCart.idVal}&cuuid=${addCart.cuuid}`
//     )
//     //const response = await axios.post('/apps/ecommerce/cart', { productId: id })
//
//     await dispatch(getProducts(getState().ecommerce.params))
//     await dispatch(getCartItems(addCart.cuuid))
//     return response.data
// })

// export const searchProducts = search => {
//     const response = axios.post(`http://localhost:85/search/search-product`, {
//         searchText: search.text,
//         regionId: search.regionId
//     })
//     return response.data
// }

export const fetchSearchResults = async query => {
    if (!query.text) {
        return
    }

    try {
        const response = await axios.post(`${jwtDefaultConfig.coreBaseUrl}/search/search-product`, {
            searchText: query.text,
            regionId: query.regionId
        })
        return response.data?.value
    } catch (error) {
        console.error('Error fetching search results', error)
        // Handle error appropriately
    }
}

export const appEcommerceSlice = createSlice({
    name: 'appEcommerce',
    initialState: {
        cart: [],
        params: {},
        products: [],
        filteredProducts: [],
        wishlist: [],
        totalProducts: 0,
        productDetail: {},
        serviceDetail: {},
        relatedServices: {},
        relatedProducts: {},
        cartItemsForCheckOut: {},
        globalProducts: [],
        discount: ''
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getProducts.fulfilled, (state, action) => {
                state.params = action.payload.params
                state.products = action.payload.data.products
                state.filteredProducts = action.payload.data.products
                state.totalProducts = action.payload.data.total
            })
            .addCase(setProducts.fulfilled, (state, action) => {
                state.filteredProducts = action.payload
            })
            .addCase(getWishlistItems.fulfilled, (state, action) => {
                state.wishlist = action.payload.products
            })
            .addCase(getCartItems.fulfilled, (state, action) => {
                if (action.payload.products !== null) {
                }

                state.cart = action.payload.products
            })
            .addCase(getDiscount.fulfilled, (state, action) => {
                state.discount = action.payload.discountCode
            })
            .addCase(getCartItemsForCheckOut.fulfilled, (state, action) => {
                state.cartItemsForCheckOut = action.payload
            })
            .addCase(getProduct.fulfilled, (state, action) => {
                state.productDetail = action.payload.selectedProduct

                state.relatedProducts = action.payload.products
            })
            .addCase(getGlobalProducts.fulfilled, (state, action) => {
                state.params = action.payload.params
                state.globalProducts = action.payload.data.products
                state.filteredProducts = action.payload.data.products
                state.totalProducts = action.payload.data.total
            })
            .addCase(getGlobalTools.fulfilled, (state, action) => {
                state.params = action.payload.params
                state.globalProducts = action.payload.data.products
                state.totalProducts = action.payload.data.total
            })
            .addCase(getGlobalProduct.fulfilled, (state, action) => {
                state.globalProductDetail = action.payload.selectedProduct

                state.globalRelatedProducts = action.payload.products
            })
            .addCase(getConsultancyService.fulfilled, (state, action) => {
                state.serviceDetail = action.payload.selectedService
                state.relatedServices = action.payload.services


            })
            .addCase(getConsultancyServices.fulfilled, (state, action) => {
                state.params = action.payload.params
                state.consultancyServices = action.payload.data.services
            })
            .addCase(setConsultancyServices.fulfilled, (state, action) => {
                state.filteredConsultancyServices = action.payload
            })
        // .addCase(addFreeProduct.fulfilled, (state, action) => {
        //     // Assuming action.payload somehow contains the product ID and ownership status
        //     const productId = action.meta.arg.idVal
        //     const productIndex = state.products.findIndex(product => product.uuid === productId)

        //     if (productIndex !== -1) {
        //         state.products[productIndex].owned = true
        //     }
        // })
    }
})

export default appEcommerceSlice.reducer
