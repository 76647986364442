// ** Reducers Imports

import navbar from './navbar'
import layout from './layout'
import auth from './authentication'

import users from '@src/views/apps/user/store'
// import appUser from '@src/tedd/academy/store'
// import appUser from '../tedd/academy'

import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import academy from '@src/tedd/store'

import shoppingCart from '../reducers/shoppingCartReducer'
import shoppingCartModal from '../reducers/shoppingCartModal'
import category from '../reducers/category'
import purchasedProducts from '../reducers/purchasedProducts'
import paymentHistory from '../reducers/paymentHistory'
import productRegion from '../reducers/productRegion'

const rootReducer = {
    academy, //! THIS REDUCER NAME DOES NOT REFLECT WHAT IT DOES INTERNALLY, IT SHOULD HAVE BEEN NAME "appUsers". REFACTORING IS NECESSARY FOR THE MAINTABAILITY OF CODE.
    auth,
    navbar,
    layout,
    users,
    calendar,
    ecommerce,
    shoppingCart,
    shoppingCartModal,
    category,
    purchasedProducts,
    paymentHistory,
    productRegion
}

export default rootReducer

// TODO
// THE FOLLOWING STATE VARIABLES ACT AS ENUMS. EVERY TIME WE ACCESS STATE VALUES, WE MAY UNKNOWINGLY MISS-SPELL VARIABLE NAMES. THIS WILL PREVENT THAT MIS-HAPS.
// IF IN THE FUTUTRE, STATE VALUES ARE ADDED INTO THIS ROOT REDUCER PLEASE DO NOT FORGET TO ADD THAT STATE VARAIBLE NAMES BELOW AND EXPORT.
export const SHOPPING_CART_STATE_VAR = 'shoppingCart'
export const ECOMMERECE_STATE_VAR = 'ecommerce'
export const LAYOUT_STATE_VAR = 'layout'
export const CALENDAR_STATE_VAR = 'calendar'
export const USERS_STATE_VAR = 'users'
export const NAVBAR_STATE_VAR = 'navbar'
export const AUTH_STATE_VAR = 'auth'
export const ACADEMY_STATE_VAR = 'academy'
export const SHOPPING_CART_MODAL_STATE_VAR = 'shoppingCartModal'
export const CATEGORY_STATE_VAR = 'category'
export const PURCHASED_PRODUCTS_STATE_VAR = 'purchasedProducts'
export const PAYMENT_HISTORY_STATE_VAR = 'paymentHistory'
export const PRODUCT_REGION_STATE_VAR = 'productRegion'
