import {useRef, useEffect} from 'react'
import HorizontalBreak from '../../learning_platform/components/HorizontalBreak'
import './style/shopping-card.scss'
import {IoCloseOutline} from 'react-icons/io5'
import {AiOutlineDelete} from 'react-icons/ai'
import {useDispatch, useSelector} from 'react-redux'
import removingShoppingCartItem from '../../actions/removeShoppingCartItem'
import computeTotalShoppingCartPrice from '../../utility/computeTotalShoppingCartPrice'
import useClientLoggedIn from '../../utility/hooks/useClientLoggedIn'
import {useNavigate} from 'react-router-dom'
// import {motion} from 'framer-motion/dist/framer-motion'
import {deleteCartItem, getCartItems} from '../../views/apps/ecommerce/store'
import {getUserData} from '../../utility/Utils'
import {SHOPPING_CART_STATE_VAR} from '../../redux/rootReducer'
import {trackUsersBehavior} from '../../utility/analytics'
import isBrowserEnv from '../../utility/isBrowserEnv'
let motion

if (isBrowserEnv()) {
    const {motion: motionModule} = require('framer-motion/dist/framer-motion')
    motion = motionModule
}

function ShoppingCart({items, isShoppingCardDisplayed, onCloseShoppingCart}) {
    const shoppingCartRef = useRef()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = getUserData()
    const isUserLoggedIn = useClientLoggedIn()
    const store = useSelector(state => state.ecommerce)
    const shoppingCartItems = useSelector(state => state[SHOPPING_CART_STATE_VAR])

    const cartItems = isUserLoggedIn ? store?.cart : shoppingCartItems

    // const ownedItem = purchasedProducts?.includes(item.uuid)

    // CLOSE "SHOPPING CART SIDEBAR" WHEN A DOM ELEMENT OUTSIDE OF THE SHOPPING CART DOM ELEMENT IS CLICKED.
    useEffect(_ => {
        const onDetectTouchOutside = event => {
            const shoppingCartDomElem = shoppingCartRef.current
            const targetedElement = event.target
            // Will return a string if there is any dataset , otherwise undefined.
            const {shoppingCartToggler} = targetedElement.dataset

            if (!shoppingCartToggler && !shoppingCartDomElem?.contains(targetedElement)) onCloseShoppingCart()
        }

        document.addEventListener('click', onDetectTouchOutside)

        return _ => document.removeEventListener('click', onDetectTouchOutside)
    }, [])

    useEffect(() => {
        if (isUserLoggedIn) dispatch(getCartItems(user.userInfo.cuuid))
    }, [])

    // handler fns
    const onCheckoutShoppingCart = args => {
        trackUsersBehavior('BUTTON: RENDERS USERS TO CHECKOUT PAGE', 'CLICK', 'CHECKOUT')
        if (!isUserLoggedIn) navigate('/login')
        else navigate('/apps/ecommerce/checkout')

        // AFTER ROUTING THE CLIENT TO THE APPROPRIATE ROUTE, UNMOUNT SHOPPING CART.
        onCloseShoppingCart()
    }

    return (
        isBrowserEnv() && (
            <motion.div
                className={`shopping-card-wrapper ${isShoppingCardDisplayed ? 'shopping-card-display' : ''}`}
                initial={{x: '100%'}}
                animate={{x: 0}}
                exit={{x: '100%'}}
                transition={{type: 'spring', stiffness: 100, damping: 20}}
                //

                ref={shoppingCartRef}>
                <button
                    onClick={onCloseShoppingCart}
                    aria-label='close shopping cart'
                    className='hide-shopping-cart-btn'>
                    <IoCloseOutline size={16} />
                </button>
                <p className='total-shopping-cart-price'>
                    <span>€</span> <span>{computeTotalShoppingCartPrice(cartItems).toFixed(2)}</span>
                </p>
                <HorizontalBreak color='rgba(0, 0, 0, .1)' width='50%' />
                {cartItems?.length ? (
                    <div className='shopping-cart-items'>
                        {cartItems?.map(item => {
                            // const extractedItem = Object.values(item)[0]
                            return (
                                <ShoppingCartItemCard
                                    data={item}
                                    key={isUserLoggedIn ? item?.productUuid : item.uuid}
                                />
                            )
                        })}
                    </div>
                ) : (
                    <p style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        Your Cart Is Empty
                    </p>
                )}
                <div className='checkout-shopping-cart-items'>
                    <button onClick={onCheckoutShoppingCart}>Checkout</button>
                </div>
            </motion.div>
        )
    )
}

export default ShoppingCart

function ShoppingCartItemCard({data}) {
    const user = getUserData()
    const dispatch = useDispatch()
    const isUserLoggedIn = useClientLoggedIn()

    return (
        <div className='shopping-cart-item-card-wrapper'>
            <img src={isUserLoggedIn ? data.productImage : data.coverImage} alt='' className='shopping-card-image' />
            <div className='shopping-item-details'>
                <p className='shopping-item-title'>{isUserLoggedIn ? data?.productName : data.name}</p>
            </div>
            <div className='delete-btn-and-price-wrapper'>
                <span className='shopping-item-price'>
                    <i> {data?.currencySymbol} </i> <i>{(data?.priceAmount).toFixed(2)}</i>
                </span>

                <button
                    data-shopping-cart-toggler='shoppingCartToggler'
                    className='from-shopping-cart-delete-item'
                    aria-label='remove item'
                    onClick={_ => {
                        {
                            dispatch(removingShoppingCartItem(isUserLoggedIn ? data?.productUuid : data.uuid))
                            if (isUserLoggedIn) {
                                dispatch(
                                    deleteCartItem({
                                        idVal: isUserLoggedIn ? data?.productUuid : data?.uuid,
                                        cuuid: user.userInfo.cuuid
                                    })
                                )
                            }
                        }
                    }}>
                    <AiOutlineDelete size={16} data-shopping-cart-toggler='shoppingCartToggler' />
                </button>
            </div>
        </div>
    )
}

// todo: IN THE FUTURE, IN CASE VECTRA SHOP SELLS PHYSICAL ITEMS: PUT BACK THE FEATURE WHERE IT INCREASES/DECREASES THE NUMBER OF PHYSICAL ITEMS:
// todo: INCLUDE THE FOLLOWING IMPORTS IN THE TOP OF THE FILE AS WELL

// import {HiPlusSm, HiMinusSm} from 'react-icons/hi'
// import increaseShoppingCartItem from '../../actions/increaseShoppingCartItem'
// import decreaseShoppingCartItem from '../../actions/decreaseShoppingCartItem'
// import toggleShoppingCart from '../../actions/toggleShoppingCart'
{
    /* <div className='number-of-items-increase-decrease'>
                    <button
                        aria-label='decrease number of items'
                        onClick={_ => {
                            if (numberOfItems === 0) {
                                const confirmation = confirm('Are you sure you want to remove this item?')
                                confirmation && dispatch(removingShoppingCartItem(uuid))
                            }
                            dispatch(decreaseShoppingCartItem(uuid))
                        }}>
                        <HiMinusSm size={16} />
                    </button>
                    <span>{numberOfItems}</span>
                    <button
                        aria-label='increase number of items'
                        onClick={_ => dispatch(increaseShoppingCartItem(uuid))}>
                        <HiPlusSm size={16} />
                    </button>
                </div> */
}
