import {Fragment} from 'react'
import AppRoutes from './Apps'
import PagesRoutes from './Pages'
import AuthenticationRoutes from './Authentication'
import BlankLayout from '@layouts/BlankLayout'
import VerticalLayout from '@src/layouts/VerticalLayout'
import HorizontalLayout from '@src/layouts/HorizontalLayout'
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper'
import PublicRoute from '@components/routes/PublicRoute'
import PrivateRoute from '@components/routes/PrivateRoute'
import {isObjEmpty} from '@utils'

// const getLayout = {
//     blank: <BlankLayout />,
//     vertical: <VerticalLayout />,
//     horizontal: <HorizontalLayout />
// }

const getLayout = {
    blank: <BlankLayout />,
    vertical: <VerticalLayout />,
    horizontal: <HorizontalLayout />
}

const TemplateTitle = '%s - Vuexy React Admin Template'
const DefaultRoute = '/dashboard/ecommerce'
const Routes = [...AuthenticationRoutes, ...AppRoutes, ...PagesRoutes]

const getRouteMeta = route => {
    if (isObjEmpty(route.element.props)) {
        if (route.meta) {
            return {routeMeta: route.meta}
        } else {
            return {}
        }
    }
}

const MergeLayoutRoutes = (layout, defaultLayout) => {
    const LayoutRoutes = []

    if (Routes) {
        Routes.filter(route => {
            let isBlank = false
            if (
                (route.meta && route.meta.layout && route.meta.layout === layout) ||
                ((route.meta === undefined || route.meta.layout === undefined) && defaultLayout === layout)
            ) {
                let RouteTag = PublicRoute
                if (route.meta) {
                    route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false)
                    RouteTag = PublicRoute
                }
                if (route.element) {
                    const Wrapper =
                        isObjEmpty(route.element.props) && isBlank === false
                            ? // eslint-disable-next-line multiline-ternary
                              LayoutWrapper
                            : Fragment

                    route.element = (
                        <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
                            <RouteTag route={route}>{route.element}</RouteTag>
                        </Wrapper>
                    )
                }

                // Push route to LayoutRoutes
                LayoutRoutes.push(route)
            }
            return LayoutRoutes
        })
    }
    return LayoutRoutes
}

const getRoutes = layout => {
    const defaultLayout = layout || 'vertical'
    const layouts = ['vertical', 'horizontal', 'blank']

    const AllRoutes = []

    layouts.forEach(layoutItem => {
        const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

        AllRoutes.push({
            path: '/',
            element: getLayout[layoutItem] || getLayout[defaultLayout],
            children: LayoutRoutes
        })
    })
    return AllRoutes
}

export {DefaultRoute, TemplateTitle, Routes, getRoutes}
