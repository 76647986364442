// import {useTranslation} from 'react-i18next'
import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {IoReorderThree, IoCloseOutline} from 'react-icons/io5'
import './style/header.scss'
import useCurrentInnerWidth from '../../utility/hooks/useCurrentInnerWidth'
// import {AnimatePresence} from 'framer-motion/dist/framer-motion'
import NavBar from './NavBar'
import {useDispatch} from 'react-redux'
import toggleShoppingCart from '../../actions/toggleShoppingCart'
import {isUserLoggedIn} from '../../utility/Utils'
import {
    DASHBOARD_DROP_DOWN,
    MARKET_PLACE_DROP_DOWN,
    TOOLS_DROP_DOWN,
    TRAININGS_DROP_DOWN,
    ACCOUNT_DROP_DOWN,
    LOGOUT_DROP_DOWN
} from '../../global_constants/fixed_variable_values'
import {handleLogout as onUserLogout} from '../../redux/authentication'
import resetShoppingCart from '../../actions/resetShoppingCard'
import isBrowserEnv from '../../utility/isBrowserEnv'

let AnimatePresence

if (isBrowserEnv()) {
    const {AnimatePresence: AnimatePresenceModule} = require('framer-motion/dist/framer-motion')
    AnimatePresence = AnimatePresenceModule
}

export default function Header({otherClassName}) {
    // (User defined) Hooks
    // const {t} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // States
    const [isOpen, setIsOpen] = useState(false)
    const [isSidebarOpen, setIsSideBarOpen] = useState(false)
    const [isFixed, setIsFixed] = useState(true)
    const currentInnerWidth = useCurrentInnerWidth()

    useEffect(() => {
        if (isBrowserEnv()) {
            const handleScroll = () => {
                const scrollPosition = window.scrollY
                setIsFixed(scrollPosition === 0)
            }
            window.addEventListener('scroll', handleScroll)
            return () => {
                window.removeEventListener('scroll', handleScroll)
            }
        }
    }, [])

    const onFavoriteProduct = args => {
        // TODO
    }

    const onShoppingCart = _ => {
        dispatch(toggleShoppingCart(true))
    }

    const onHeaderDropDownMenu = (flag) /** flag is one of the drop down options */ => {
        // USER IS NOT LOGGED IN , EARLY RETURN.
        if (!isUserLoggedIn) {
            navigate('/login')
            return
        }

        switch (flag) {
            case ACCOUNT_DROP_DOWN:
                navigate('/apps/campus')
                return
            case TRAININGS_DROP_DOWN:
                navigate('/apps/campus')
                return
            case DASHBOARD_DROP_DOWN:
                navigate('/user-preferences/profile')
                return
            case MARKET_PLACE_DROP_DOWN:
                navigate('/apps/ecommerce/shop')
                return
            case TOOLS_DROP_DOWN:
                navigate('/apps/vectra-tools')
                return

            case LOGOUT_DROP_DOWN:
                dispatch(onUserLogout())
                dispatch(resetShoppingCart())
                navigate('/login')
                window.location.reload() // Remove any pertaining state.
                return

            default:
                return null
        }
    }

    const headerNavHandlers = {
        onFavoriteProduct,
        onShoppingCart,
        onHeaderDropDownMenu
    }
    return (
        <div className='vshc-vectra-shop-header-container'>
            <div className='vectra-shop-header-wrapper'>
                <a href='https://www.vectra-intl.com' target='_blank'>
                    <img src='/assets/landing-images/vectra-logo-svg.svg' alt='Logo' style={{height: '70px'}}/>
                </a>

                {currentInnerWidth >= 1024 &&
                    <>
                        <div style={{
                            backgroundColor: '#00B8D4', // The blue background color
                            color: 'white', // Text color
                            fontSize: '16px',
                            textAlign: 'center', // Center the text horizontally
                            width: '100vw', // Use viewport width to ensure it spans full width
                            position: 'fixed', // Fixed or absolute position
                            left: 0, // Align to the left edge
                            top: 0, // Align to the top edge
                            zIndex: 1000, // Ensure it's on top of other elements
                            display: 'flex', // Use flexbox for alignment
                            alignItems: 'center', // Align items vertically
                            justifyContent: 'center' // Align items horizontally

                        }}>
                            <h6 style={{
                                margin: 2,
                                color: 'white', // Text color
                                fontSize: '16px'
                            }}>Enabling Positive Impact</h6>
                        </div>
                        <NavBar {...headerNavHandlers}  />
                    </>}

                {AnimatePresence && (
                    <AnimatePresence>
                        {currentInnerWidth < 1024 && isSidebarOpen && (
                            <>
                                <div style={{
                                    backgroundColor: '#00B8D4', // The blue background color
                                    color: 'white', // Text color
                                    fontSize: '16px',
                                    textAlign: 'center', // Center the text horizontally
                                    width: '100vw', // Use viewport width to ensure it spans full width
                                    position: 'fixed', // Fixed or absolute position
                                    left: 0, // Align to the left edge
                                    top: 0, // Align to the top edge
                                    zIndex: 1000, // Ensure it's on top of other elements
                                    display: 'flex', // Use flexbox for alignment
                                    alignItems: 'center', // Align items vertically
                                    justifyContent: 'center' // Align items horizontally

                                }}>
                                    <h6 style={{
                                        margin: 5,
                                        color: 'white', // Text color
                                        fontSize: '16px'
                                    }}>Enabling Positive Impact</h6>
                                </div>
                                <NavBar {...headerNavHandlers} smallScreen={true}/>
                            </>
                        )}
                    </AnimatePresence>
                )}
                {currentInnerWidth < 1024 && (
                    <button
                        onClick={_ => setIsSideBarOpen(!isSidebarOpen)}
                        aria-label={`${isSidebarOpen ? 'close side' : 'open side'} nav bar`}>
                        {isSidebarOpen ? <IoCloseOutline size={20}/> : <IoReorderThree size={20}/>}
                    </button>
                )}
            </div>
        </div>
    )
}
