import {LOAD_PAYMENT_HISTORY} from '../actions/actionNames'
import {getFromLocal, storeOnLocal} from '../learning_platform/utils/localStorage'
import {PAYMENT_HISTORY} from '../global_constants/fixed_variable_values'

const initialState = getFromLocal(PAYMENT_HISTORY) ?? null

const paymentHistory = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PAYMENT_HISTORY:
            const {data} = action.payload
            storeOnLocal(PAYMENT_HISTORY, data)
            return data

        default:
            return state
    }
}

export default paymentHistory
