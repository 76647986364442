import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import useClientLoggedIn from './useClientLoggedIn'

const useAuthRedirect = _ => {
    const navigate = useNavigate()
    const loggedIn = useClientLoggedIn()

    useEffect(() => {
        if (!loggedIn) {
            navigate('/login')
        }
    }, [loggedIn])

    return !loggedIn // User not logged in.
}

export default useAuthRedirect
