import {LOAD_PURCHASED_PRODUCTS} from './actionNames'

const loadPurchasedProducts = purchasedProducts => {
    return {
        type: LOAD_PURCHASED_PRODUCTS,
        payload: {purchasedProducts}
    }
}

export default loadPurchasedProducts
