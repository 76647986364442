function HorizontalLineBreak({width, style}) {
    return (
        <hr
            style={{
                width: `${width || '80'}%`,
                margin: '0 auto',
                padding: '.09rem',
                color: 'whitesmoke',
                margin: '.25rem auto',
                ...style
            }}
        />
    )
}
export default HorizontalLineBreak
