/*eslint-disable */
import mock from '../mock'

const date = new Date()
const prevDay = new Date().getDate() - 1
const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

// prettier-ignore
const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)
// prettier-ignore
const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

const data = {


    events: [
        {
            id: 1,
            url: '/apps/campus',
            title: 'Sorgfaltspflichten in der Unternehmenspraxis - Modul 1 Risikoanalyse',
            start: new Date(2022, 9, 17),
            end: new Date(2022, 9, 24),
            allDay: true,
            extendedProps: {
                calendar: 'Business'
            }
        },
        {
            id: 2,
            url: '/apps/campus',
            title: 'Sorgfaltspflichten in der Unternehmenspraxis - Modul 2 Vorbeugende Maßnahmen',
            start: new Date(2022, 9, 24),
            end: new Date(2022, 9, 30),
            allDay: true,
            extendedProps: {
                calendar: 'Business'
            }
        },
        {
            id: 3,
            url: '/apps/campus',
            title: 'Sorgfaltspflichten in der Unternehmenspraxis - Modul 3 Abhilfe',
            allDay: true,
            start: new Date(2022, 9, 31),
            end: new Date(2022, 10, 6),
            extendedProps: {
                calendar: 'Holiday'
            }
        },
        {
            id: 4,
            url: '/apps/campus',
            title: 'Sorgfaltspflichten in der Unternehmenspraxis - Modul 4 Beschwerdemechanismen',
            start: new Date(2022, 11, 7),
            end: new Date(2022, 11, 13),
            allDay: true,
            extendedProps: {
                calendar: 'Personal'
            }
        },
        {
            id: 5,
            url: '/apps/campus',
            title: 'Modül 1: Risk analizi',
            start: new Date(2022, 11, 21),
            end: new Date(2022, 11, 27),
            allDay: true,
            extendedProps: {
                calendar: 'Business'
            }
        },
        {
            id: 6,
            url: '/apps/campus',
            title: 'Modül 2: Önleyici tedbirler',
            start: new Date(2022, 11, 28),
            end: new Date(2022, 11, 4),
            allDay: true,
            extendedProps: {
                calendar: 'Personal'
            }
        },
        {
            id: 7,
            url: '/apps/campus',
            title: 'Modül 3: İyileştirme',
            start: new Date(2022, 12, 5),
            end: new Date(2022, 12, 11),
            allDay: true,
            extendedProps: {
                calendar: 'Holiday'
            }
        },
        {
            id: 8,
            url: '/apps/campus',
            title: 'Modül 4: Şikâyet mekanizmaları',
            start: new Date(2022, 12, 12),
            end: new Date(2022, 12, 18),
            allDay: true,
            extendedProps: {
                calendar: 'Personal'
            }
        },
        {
            id: 11,
            url: '/apps/campus',
            title: 'Module 1: Risk analysis',
            start: new Date(2022, 10, 31),
            end: new Date(2022, 11, 6),
            allDay: true,
            extendedProps: {
                calendar: 'Business'
            }
        },
        {
            id: 12,
            url: '/apps/campus',
            title: 'Module 2: Preventative actions',
            start: new Date(2022, 11, 7),
            end: new Date(2022, 11, 13),
            allDay: true,
            extendedProps: {
                calendar: 'Personal'
            }
        },
        {
            id: 13,
            url: '/apps/campus',
            title: 'Module 3: Remediation',
            start: new Date(2022, 11, 14),
            end: new Date(2022, 11, 20),
            allDay: true,
            extendedProps: {
                calendar: 'Holiday'
            }
        },
        {
            id: 14,
            url: '/apps/campus',
            title: 'Module 4: Grievance mechanisms',
            start: new Date(2022, 11, 21),
            end: new Date(2022, 11, 27),
            allDay: true,
            extendedProps: {
                calendar: 'Personal'
            }
        },
        {
            id: 16,
            url: '/apps/campus',
            title: 'Module 1: Risk analysis',
            start: new Date(2022, 11, 7),
            end: new Date(2022, 11, 13),
            allDay: true,
            extendedProps: {
                calendar: 'Business'
            }
        },
        {
            id: 17,
            url: '/apps/campus',
            title: 'Module 2: Preventative actions',
            start: new Date(2022, 11, 14),
            end: new Date(2022, 11, 20),
            allDay: true,
            extendedProps: {
                calendar: 'Personal'
            }
        },
        {
            id: 18,
            url: '/apps/campus',
            title: 'Module 3: Remediation',
            start: new Date(2022, 11, 21),
            end: new Date(2022, 11, 27),
            allDay: true,
            extendedProps: {
                calendar: 'Holiday'
            }
        },
        {
            id: 19,
            url: '/apps/campus',
            title: 'Module 4: Grievance mechanisms',
            start: new Date(2022, 11, 28),
            end: new Date(2022, 12, 4),
            allDay: true,
            extendedProps: {
                calendar: 'Personal'
            }
        }
    ]
}

// ------------------------------------------------
// GET: Return calendar events
// ------------------------------------------------
mock.onGet('/apps/calendar/events').reply(config => {
  // Get requested calendars as Array
  const calendars = config.calendars

  return [200, data.events.filter(event => calendars.includes(event.extendedProps.calendar))]
})

// ------------------------------------------------
// POST: Add new event
// ------------------------------------------------
mock.onPost('/apps/calendar/add-event').reply(config => {
  // Get event from post data
  const { event } = JSON.parse(config.data)

  const { length } = data.events
  let lastIndex = 0
  if (length) {
    lastIndex = data.events[length - 1].id
  }
  event.id = lastIndex + 1

  data.events.push(event)

  return [201, { event }]
})

// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------
mock.onPost('/apps/calendar/update-event').reply(config => {
  const { event: eventData } = JSON.parse(config.data)

  // Convert Id to number
  eventData.id = Number(eventData.id)

  const event = data.events.find(ev => ev.id === Number(eventData.id))
  Object.assign(event, eventData)

  return [200, { event }]
})

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete('/apps/calendar/remove-event').reply(config => {
  // Get event id from URL
  let { id } = config

  // Convert Id to number
  const eventId = Number(id)

  const eventIndex = data.events.findIndex(ev => ev.id === eventId)
  data.events.splice(eventIndex, 1)
  return [200]
})
