export const SHOPPING_CART_ITEMS = 'shoppingCartItems'
export const USER_DATA = 'userData'
export const CLIENT_SELECTED_CATEGORY = 'clientSelectedCategory'
export const PURCHASED_PRODUCTS = 'purchasedProducts'
export const PAYMENT_HISTORY = 'paymentHistory'

export const ACCOUNT_DROP_DOWN = 'ACCOUNT'
export const TRAININGS_DROP_DOWN = 'TRAININGS'
export const TOOLS_DROP_DOWN = 'TOOLS'
export const MARKET_PLACE_DROP_DOWN = 'MARKET_PLACE'
export const DASHBOARD_DROP_DOWN = 'DASHBOARD'
export const LOGOUT_DROP_DOWN = 'LOGOUT'
export const PRODUCT_REGION = 'productRegion'
