import isBrowserEnv from '../../utility/isBrowserEnv'
export const storeOnLocal = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
}
export const getFromLocal = key => {
    if (!isBrowserEnv()) return

    return JSON.parse(localStorage.getItem(key))
}
export const deleteFromLocal = key => {
    if (!isBrowserEnv()) return

    if (localStorage.getItem(key)) {
        localStorage.removeItem(key)
        return true
    } else return false
}
export const clearLocal = () => {
    isBrowserEnv() && localStorage.clear()
}

// SEARCH: LOCAL STORAGE
