import {useEffect, useState} from 'react'
import './search-tool.scss'
import {BiSearch} from 'react-icons/bi'
import axios from 'axios'
import {getUserData} from '@utils'
import {debounce} from 'lodash'
import {fetchSearchResults} from '../../../../../views/apps/ecommerce/store'

function SearchTool({onSearch, placeholder, flag}) {
    const [regionId, setRegionId] = useState(8)
    const [results, setResults] = useState([])
    const [loggedIn, setLoggedIn] = useState(false)
    const [productBaseUrl, setProductBaseUrl] = useState('/product-detail/')
    useEffect(() => {
        const user = getUserData()

        if (user) {
            setLoggedIn(true)
            setProductBaseUrl('/apps/ecommerce/product-detail/')
        } else {
            setLoggedIn(false)
            setProductBaseUrl('/product-detail/')
        }
        getGeoInfo()
    }, [])

    const getGeoInfo = () => {
        axios
            .get('https://ipapi.co/json/')
            .then(response => {
                const data = response.data

                if (data.country_name === 'India') {
                    setRegionId(3)
                } else if (
                    data.country_name === 'Germany' ||
                    data.country_name === 'Austria' ||
                    data.country_name === 'Switzerland'
                ) {
                    setRegionId(1)
                } else if (data.country_name === 'Bangladesh') {
                    setRegionId(4)
                } else if (
                    data.country_name === 'Belgium' ||
                    data.country_name === 'Netherlands' ||
                    data.country_name === 'Luxembourg'
                ) {
                    setRegionId(7)
                } else {
                    setRegionId(8)
                }
            })
            .catch(error => {})
    }

    const [text, setText] = useState('')

    const performSearch = async searchQuery => {
        if (!searchQuery.text.trim()) {
            setResults([]) // Clear results if the search query is empty
            return
        }
        try {
            // Call your search service or API endpoint
            const results = await fetchSearchResults(searchQuery)
            setResults(results) // Update the state with the fetched results
        } catch (error) {
            console.error('Error fetching search results', error)
            setResults([]) // Reset results on error
        }
    }

    const debouncedSearch = debounce(performSearch, 300)

    useEffect(() => {
        debouncedSearch({text, regionId})
        // Cleanup function to cancel the debounced call if component unmounts
        return () => debouncedSearch.cancel()
    }, [text])

    const renderSearchResults = () => {
        return (
            results?.length > 0 && (
                <ul className='search-results-list'>
                    {results.map((result, index) => (
                        <li key={index} className='search-result-item'>
                            <a
                                href={`${productBaseUrl}${result.uuid}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='search-result-link'>
                                <img src={result.coverImage} alt={result.name} className='search-result-image' />
                                <span className='search-result-text'>{result.name}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            )
        )
    }
    // ! debounce this handler.
    const onInput = evt => {
        setText(evt.target.value)
    }

    const wrapperClass = 'st-search-tool-wrapper'
    const commonJsx = (
        <>
            <span className='search-icon'>
                <BiSearch size={20} color='black' />
            </span>

            {flag === 'immediate' ? (
                <input
                    type='text'
                    className='search-anything'
                    aria-label='search anything'
                    placeholder={placeholder || 'Search anything'}
                    onChange={onSearch}
                />
            ) : (
                <input
                    value={text}
                    type='text'
                    className='search-anything'
                    aria-label='search anything'
                    placeholder={placeholder || 'Search anything'}
                    onChange={onInput}
                />
            )}
            {renderSearchResults()}
            {}
        </>
    )

    // Immediate Response, this does not wait for form submission.
    if (flag === 'immediate') {
        return (
            <div>
                <div className={wrapperClass}>{commonJsx}</div>
                {renderSearchResults()}
            </div>
        )
    }
    return (
        <form className={wrapperClass} onSubmit={evt => onSearch(evt, text)}>
            {commonJsx}
        </form>
    )
}

export default SearchTool
