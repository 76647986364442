import './footer.scss'
import {Link} from 'react-router-dom'
import {RiLinkedinLine as LinkedIn, RiYoutubeLine as Youtube, RiTwitterXLine as Twitter} from 'react-icons/ri'
import {GrFacebookOption as Facebook} from 'react-icons/gr'
import {RxInstagramLogo as Instagram} from 'react-icons/rx'
import HorizontalLineBreak from '../line-break/HorizontalLineBreak'
function Footer() {
    return (
        <div className='f-footer'>
            <div className='important-links'>
                <div className='vectra-logo'>
                    <Link to='/'>
                        <img src='/assets/landing-images/vectra-white-logo.png' alt='vectra logo' />
                    </Link>
                </div>
                <ul className='quick-links-wrapper'>
                    <h4>VECTRA International</h4>
                    <li>
                        <a className='quick-link' target="_blank"  href='https://vectra-intl.com'>
                            Home Page
                        </a>
                    </li>

                    <li>
                        <a className='quick-link' target="_blank"  href='https://vectra-intl.com/site-map'>
                            Sitemap
                        </a>
                    </li>
                    <li>
                        <a href='https://www.vectra-intl.com/contact-us' target="_blank"  className='quick-link'>
                            Contact Us
                        </a>
                    </li>
                </ul>

                <ul className='our-brands-wrapper'>
                    <h4>Our Brands</h4>
                    <li>
                        <a className='our-brand' target="_blank"  href='https://vectra-intl.com'>
                            VECTRA
                        </a>
                    </li>
                    {/*<li>*/}
                    {/*    <a className='our-brand' href='https://vectra-intl.com/tedd'>*/}
                    {/*        TEDD*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a className='our-brand' href='https://vectra-intl.com/futurefitsme'>*/}
                    {/*        FutureFitSME*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href='https://campus.vectra-intl.com' className='our-brand'>*/}
                    {/*        CAMPUS*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                </ul>
                <ul className='our-brands-wrapper'>
                    <h4>Quick Links</h4>
                    <li>
                        <a className='quick-link' target="_blank"  href='https://vectra-intl.com/privacy-policy'>
                            Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a className='our-brand' target="_blank"  href='/terms-and-conditions'>
                            Terms & Conditions
                        </a>
                    </li>
                    <li>
                        <a className='our-brand' target="_blank"  href='/faqs'>
                            FAQ
                        </a>
                    </li>
                    <li>
                        <a className='quick-link' target="_blank"  href='https://vectra-intl.com/cookie-policy'>
                            Cookie Policy
                        </a>
                    </li>
                    {/*<li>*/}
                    {/*    <a className='our-brand' href='https://vectra-intl.com/tedd'>*/}
                    {/*        TEDD*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a className='our-brand' href='https://vectra-intl.com/futurefitsme'>*/}
                    {/*        FutureFitSME*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href='https://campus.vectra-intl.com' className='our-brand'>*/}
                    {/*        CAMPUS*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                </ul>

                <ul className='social-media-wrapper'>
                    <li>
                        <a
                            aria-label='linkedin'
                            className='social-media'
                            target='_blank'
                            href='https://www.linkedin.com/company/vectra-international/'>
                            <LinkedIn size={20} />
                        </a>
                    </li>
                    <li>
                        <a
                            aria-label='facebook'
                            className='social-media'
                            target='_blank'
                            href='https://www.facebook.com/vectrainternational'>
                            <Facebook size={20} />
                        </a>
                    </li>
                    <li>
                        <a
                            aria-label='youtube'
                            className='social-media'
                            target='_blank'
                            href='https://www.youtube.com/@vectrainternationalhq3566'>
                            <Youtube size={20} />
                        </a>
                    </li>
                    <li>
                        <a
                            aria-label='instagram'
                            className='social-media'
                            target='_blank'
                            href='https://www.instagram.com/vectrainternational/'>
                            <Instagram size={20} />
                        </a>
                    </li>
                    <li>
                        <a className='social-media' target='_blank' href='https://twitter.com/VECTRAintl'>
                            <Twitter size={14} />
                        </a>
                    </li>
                </ul>
            </div>

            <ul className='vectra-address'>
                <li>Chaussée de Wavre 1517B, B-1160 Brussels, Belgium</li>
                <li>
                    <a href='tel:+32495239088'>+32 495 239088</a>
                </li>
                <li>
                    <a href='mailto:info@tedd-intl.com'>info@vectra-intl.com</a>
                </li>
            </ul>
            <HorizontalLineBreak width={100} style={{backgroundColor: 'rgb(255, 255, 255, .1)'}} />
            <div className='vectra-trademark-and-right'>
                <p>© 2023 VECTRA International | All Rights Reserved</p>
                <a target='_blank' href='https://www.vectra-intl.com'>
                    Powered by <span className='vectra-intl'>VECTRA International</span>
                </a>
            </div>
        </div>
    )
}

export default Footer
