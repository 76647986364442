import {TOGGLE_SHOPPING_CART} from './actionNames'

const toggleShoppingCart = isOpen => {
    return {
        type: TOGGLE_SHOPPING_CART,
        payload: {isOpen}
    }
}

export default toggleShoppingCart
