import {useState, useEffect} from 'react'
import isBrowserEnv from '../isBrowserEnv'
const useCurrentInnerWidth = () => {
    const [currentInnerWidth, setCurrentInnerWidth] = useState(isBrowserEnv() ? window.innerWidth : 0)

    useEffect(() => {
        if (isBrowserEnv()) {
            function onInnerWidth() {
                setCurrentInnerWidth(window.innerWidth)
            }

            window.addEventListener('resize', onInnerWidth)

            return () => window.removeEventListener('resize', onInnerWidth)
        }
    }, [])
    return currentInnerWidth
}

export default useCurrentInnerWidth
