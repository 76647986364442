import './index.scss'
import SideNavBar from '../components/sidebar/SideNavBar'
import Header from '../../../tedd-landing/components/_Header'
import LocationAwareProductSearch from '../components/location-aware-product-search/LocationAwareProductSearch'
import Footer from '../components/footer/Footer'
import Gutter from '../../../tedd-landing/components/Gutter'
import Container from '../components/container/Container'
import useScrollToTop from '../../../utility/hooks/useScrollToTop'
import useAuthRedirect from '../../../utility/hooks/useAuthRedirect'

function DashboardPage({children}) {
    useScrollToTop()

    // IF A USER MANUALLY GOES TO PROTECTED PAGES, FOR EXAMPLE, BY PLACING PROTECTED ROUTES INTO BROWSER URL INPUT FIELD, FIRST WE CHECK
    // IF THE USER IS AUTHENTICATED OR NOT. IF AUTHENTICATED, WE LET THEM USE. IF NOT WE ROUTE THEM BACK TO LOGIN PAGE( THEIR INTENTION IS TO USE PROTECTED PAGES & THUS THEY NEED TO LOG IN FIRST.)
    // "useAuthRedirect" HOOK SHORTLY DOES IT.
    const authRedirect = useAuthRedirect()
    if (authRedirect) return 'Wait...'

    return (
        <div className='dp-dashboard-page'>
            <Header />
            <Gutter />
            <Container>
                <LocationAwareProductSearch />
                <br />
                <div className='sidebar-and-tab-components'>
                    <SideNavBar />
                    <div className='tab-component'>{children}</div>
                </div>
            </Container>

            <Footer />
        </div>
    )
}

export default DashboardPage
