import {Suspense} from 'react'
import Router from './router/Router'
import './index.scss'
import ReactGA from 'react-ga4'

export const GOOGLE_ANALYTICS_MEASUREMENT_ID = 'G-QQGK4Q98WN'
ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID)
function App() {
    return (
        <Suspense fallback={null}>
            <Router />
        </Suspense>
    )
}

export default App
