function HorizontalBreak({thickness = 2, color = 'gray', width = '25%'}) {
    return (
        <div
            style={{
                width,
                border: 'none',
                borderBottom: `${thickness}px solid ${color}`
            }}
        />
    )
}

export default HorizontalBreak
