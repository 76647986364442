import {Link, useNavigate} from 'react-router-dom'
import Avatar from '@components/avatar'
import {getUserData} from '@utils'
import {useDispatch} from 'react-redux'
import {handleLogout} from '@store/authentication'
import {User, Mail, CheckSquare, MessageSquare, Settings, CreditCard, HelpCircle, Power} from 'react-feather'
import {UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem} from 'reactstrap'
import defaultAvatar from '@src/assets/images/portrait/small/avatar-s-11.jpg'
import {RiHomeLine} from 'react-icons/ri'

const UserDropdown = () => {
    // ** Store Vars

    const user = getUserData()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    // ** State
    // const [userData, setUserData] = useState(null)

    //** ComponentDidMount
    // useEffect(() => {
    //   if (isUserLoggedIn() !== null && user === null) {
    //
    //     setUserData(JSON.parse(localStorage.getItem('userData')))
    //
    //   }
    // }, [])

    //** Vars
    const userAvatar = (user && user.userInfo.profileImage) || defaultAvatar

    // CHECK IF USER'S LOCALSTORAGE IS CLEARED, IF SO THE USER HAS TO BE LOGGED OUT.
    if (!user) {
        navigate('/login')
        return
    }

    return (
        <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
            <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
                <div className='user-nav d-sm-flex d-none'>
                    <span className='user-name fw-bold'>{user.userInfo.displayName}</span>
                    {/*<span className='user-status'>{(userData && userData.role) || 'Admin'}</span>*/}
                </div>
                <Avatar img={userAvatar} imgHeight='40' imgWidth='40' status='online' />
            </DropdownToggle>
            <DropdownMenu end>
                <DropdownItem tag={Link} to='/'>
                    <RiHomeLine size={14} className='me-75' />
                    <span className='align-middle'>Home</span>
                </DropdownItem>
                <DropdownItem tag={Link} to='/user-preferences/profile'>
                    <User size={14} className='me-75' />
                    <span className='align-middle'>Profile</span>
                </DropdownItem>
                {/*<DropdownItem tag={Link} to='/apps/email'>*/}
                {/*  <Mail size={14} className='me-75' />*/}
                {/*  <span className='align-middle'>Inbox</span>*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem tag={Link} to='/apps/todo'>*/}
                {/*  <CheckSquare size={14} className='me-75' />*/}
                {/*  <span className='align-middle'>Tasks</span>*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem tag={Link} to='/apps/chat'>*/}
                {/*  <MessageSquare size={14} className='me-75' />*/}
                {/*  <span className='align-middle'>Chats</span>*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem divider />*/}
                {/*<DropdownItem tag={Link} to='/pages/account-settings'>*/}
                {/*  <Settings size={14} className='me-75' />*/}
                {/*  <span className='align-middle'>Settings</span>*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem tag={Link} to='/pages/pricing'>*/}
                {/*  <CreditCard size={14} className='me-75' />*/}
                {/*  <span className='align-middle'>Pricing</span>*/}
                {/*</DropdownItem>*/}

                <DropdownItem tag={Link} to='/login' onClick={() => dispatch(handleLogout())}>
                    <Power size={14} className='me-75' />
                    <span className='align-middle'>Logout</span>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default UserDropdown
