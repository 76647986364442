import {LOAD_PRODUCT_REGION} from './actionNames'

const changeProductRegion = value => {
    return {
        type: LOAD_PRODUCT_REGION,
        payload: {region: value}
    }
}

export default changeProductRegion
