import {
    ADD_ITEM_TO_SHOPPING_CART,
    INCREASE_SHOPPING_CART_ITEM,
    DESCREASE_SHOPPING_CART_ITEM,
    REMOVE_SHOPPING_CART_ITEM,
    RESET_SHOPPING_CART
} from '../actions/actionNames'
import {storeOnLocal, getFromLocal} from '../learning_platform/utils/localStorage'
import {SHOPPING_CART_ITEMS} from '../global_constants/fixed_variable_values'
import {isUserLoggedIn} from '../utility/Utils'

const initialState = getFromLocal(SHOPPING_CART_ITEMS) ?? []

const shoppingCartReducer = (state = initialState, action) => {
    //USER LOGGED IN, CART ITEMS ARE NOT STORED IN LOCAL STORAGE, EARLY BREAK FROM SWITCH CASES.

    let newShoppingCartItems, id
    switch (action.type) {
        case ADD_ITEM_TO_SHOPPING_CART:
            if (isUserLoggedIn()) return initialState
            const cartItem = state.find(item => item.uuid === action.payload.uuid)
            if (!cartItem) {
                newShoppingCartItems = [action.payload, ...state]
                storeOnLocal(SHOPPING_CART_ITEMS, newShoppingCartItems)
                return newShoppingCartItems
            }
            return state

        case INCREASE_SHOPPING_CART_ITEM:
            id = action.payload.id
            newShoppingCartItems = [...state].map(item => {
                if (item[id]) return {[id]: {...item[id], numberOfItems: item[id].numberOfItems + 1}}
                return item
            })
            storeOnLocal(SHOPPING_CART_ITEMS, newShoppingCartItems)
            return newShoppingCartItems

        case DESCREASE_SHOPPING_CART_ITEM:
            id = action.payload.id
            newShoppingCartItems = [...state].map(item => {
                if (item[id] && item[id].numberOfItems > 0) {
                    // Item can not be zero.
                    return {[id]: {...item[id], numberOfItems: item[id].numberOfItems - 1}}
                }
                return item
            })

            storeOnLocal(SHOPPING_CART_ITEMS, newShoppingCartItems)
            return newShoppingCartItems

        case REMOVE_SHOPPING_CART_ITEM:
            id = action.payload.id
            newShoppingCartItems = [...state].filter(item => item.uuid !== id)
            storeOnLocal(SHOPPING_CART_ITEMS, newShoppingCartItems)
            return newShoppingCartItems
        case RESET_SHOPPING_CART:
            storeOnLocal(SHOPPING_CART_ITEMS, [])
            return []

        default:
            return state
    }
}

export default shoppingCartReducer
