function computeTotalShoppingCartPrice(shoppingCartItems) {
    const totalPrice = shoppingCartItems.reduce((cumulative, shoppingCartItem) => {
        // const formattedItem = Object.values(shoppingCartItem)[0]

        // const {item, numberOfItems} = formattedItem

        return shoppingCartItem?.priceAmount + cumulative
    }, 0)

    return totalPrice
}

export default computeTotalShoppingCartPrice
