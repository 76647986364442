import {useEffect, useState} from 'react'
function useInnerWidth() {
    const [innerWidth, setInnerWidth] = useState(0)
    useEffect(() => {
        setInnerWidth(window.innerWidth)
        function onResizeWindow(e) {
            setInnerWidth(e.currentTarget.innerWidth)
        }

        window.addEventListener('resize', onResizeWindow)

        return () => window.removeEventListener('resize', onResizeWindow)
    }, [])

    return [innerWidth]
}

export default useInnerWidth
