export const ADD_ITEM_TO_SHOPPING_CART = 'ADD_ITEM_TO_SHOPPING_CART'
export const INCREASE_SHOPPING_CART_ITEM = 'INCREASE_SHOPPING_CART_ITEM'
export const DESCREASE_SHOPPING_CART_ITEM = 'DECREASE_SHOPPING_CART_ITEM'
export const REMOVE_SHOPPING_CART_ITEM = 'REMOVE_SHOPPING_CART_ITEM'
export const TOGGLE_SHOPPING_CART = 'TOGGLE_SHOPPING_CART'
export const SELECT_CATEGORY = 'SELECT_CATEGORY'
export const LOAD_PURCHASED_PRODUCTS = 'LOAD_PURCHASED_PRODUCTS'
export const LOAD_PAYMENT_HISTORY = 'LOAD_PAYMENT_HISTORY'
export const RESET_SHOPPING_CART = 'RESET_SHOPPING_CART'
export const LOAD_PRODUCT_REGION = 'LOAD_PRODUCT_REGION'
