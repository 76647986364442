import './style/nav-bar.scss'
import {useState, useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {PiShoppingCart, PiChalkboardTeacherThin} from 'react-icons/pi'
import {BsPerson} from 'react-icons/bs'
import './style/header.scss'
// import {motion} from 'framer-motion/dist/framer-motion'
import {useSelector, useDispatch} from 'react-redux'
import {SHOPPING_CART_STATE_VAR, PURCHASED_PRODUCTS_STATE_VAR} from '../../redux/rootReducer'
import useClientLoggedIn from '../../utility/hooks/useClientLoggedIn'
import {getFromLocal} from '../../learning_platform/utils/localStorage'
import {
    USER_DATA,
    DASHBOARD_DROP_DOWN,
    MARKET_PLACE_DROP_DOWN,
    TOOLS_DROP_DOWN,
    TRAININGS_DROP_DOWN,
    ACCOUNT_DROP_DOWN,
    LOGOUT_DROP_DOWN
} from '../../global_constants/fixed_variable_values'
import {DropdownToggle, DropdownItem, DropdownMenu, Button, Dropdown} from 'reactstrap'
import {HiMiniPower} from 'react-icons/hi2'
import {RxCaretDown, RxDashboard} from 'react-icons/rx'
import {VscTools as Tools} from 'react-icons/vsc'
import {MdOutlineManageAccounts} from 'react-icons/md'
import {BiShoppingBag} from 'react-icons/bi'
// import removingShoppingCartItem from '../../actions/removeShoppingCartItem'
import {getUserData} from '../../utility/Utils'
import {getCartItems} from '../../views/apps/ecommerce/store'
import isBrowserEnv from '../../utility/isBrowserEnv'

let motion

if (isBrowserEnv()) {
    const {motion: motionModule} = require('framer-motion/dist/framer-motion')
    motion = motionModule
}

const BLOOD_ORANGE_COLOR = '#ff4f04'

const ROUTES = {
    HOME: '/',
    ALL_PRODUCTS: '/all-products',
    ABOUT_US: '/about_us',
    CONTACT_US: '/contact_us'
}

function NavBar({smallScreen, onShoppingCart, onFavoriteProduct, onHeaderDropDownMenu}) {
    const {pathname} = useLocation()
    const dispatch = useDispatch()
    const user = getUserData()
    const shoppingCartItems = useSelector(state => state[SHOPPING_CART_STATE_VAR])
    const store = useSelector(state => state.ecommerce)
    const isUserLoggedIn = useClientLoggedIn()

    const userDataFromLocalSt = getFromLocal(USER_DATA)

    const displayName = userDataFromLocalSt?.userInfo?.displayName

    // const purchasedProducts = useSelector(state => state[PURCHASED_PRODUCTS_STATE_VAR])
    // const filteredShoppingCartItems = shoppingCartItems.filter(item => {
    //     const uuid = Object.values(item)[0]?.uuid
    //     return !purchasedProducts?.includes(uuid)
    // })

    // toggle drop down
    const [dropDownOpen, setDropDownOpen] = useState(false)

    // useEffect(
    //     _ => {
    //         if (isUserLoggedIn) {
    //             shoppingCartItems.forEach(item => {
    //                 const uuid = Object.values(item)[0]?.uuid
    //                 if (purchasedProducts?.includes(uuid)) dispatch(removingShoppingCartItem(uuid))
    //             })
    //         }
    //     },
    //     [isUserLoggedIn]
    // )
    useEffect(() => {
        // dispatch(getDiscount(user.userInfo.cuuid))
        if (isUserLoggedIn) {
            dispatch(getCartItems(user.userInfo.cuuid))
        } else {
            // User not logged in
        }
    }, [])

    const navLists = (
        <>

            <ul className={`header-nav-tab-wrapper ${smallScreen ? 'header-nav-tab-on-hover' : ''}`}>
                {smallScreen && (
                    <li>
                        <a href='https://www.vectra-intl.com' target='_blank'>
                            <img src='/assets/landing-images/vectra-logo-svg.svg' alt='Logo' style={{height: '70px'}}/>
                        </a>
                    </li>
                )}
                {/* <li className='home'>
                    <a
                        href='https://www.vectra-intl.com'
                        target='_blank'
                        style={{color: pathname === ROUTES.HOME ? BLOOD_ORANGE_COLOR : ''}}>
                        VECTRA
                    </a>
                </li> */}
                <li className='home'>
                    <Link to='/' style={{color: pathname === ROUTES.HOME ? BLOOD_ORANGE_COLOR : ''}}>
                        Home
                    </Link>
                </li>
                <li className='all-products'>
                    <Link
                        to='/all-products'
                        style={{color: pathname === ROUTES.ALL_PRODUCTS ? BLOOD_ORANGE_COLOR : ''}}>
                        All Products
                    </Link>
                </li>
                <li className='about-us'>
                    {/* <Link to='/about-us' style={{color: pathname === ROUTES.ABOUT_US ? BLOOD_ORANGE_COLOR : ''}}>
                        About Us
                    </Link> */}
                    <a
                        target='_blank'
                        href='https://www.vectra-intl.com/in/about-us'
                        style={{color: pathname === ROUTES.ABOUT_US ? BLOOD_ORANGE_COLOR : ''}}

                        // IF ABOUT_US PAGE IS WITHIN THE CONTEXT OF VECTRA CAMPUS APP, THEN USE THE ABOVE REACT ROUTER LINK INSTEAD.
                    >
                        About Us
                    </a>
                </li>
                <li className='contact-us'>
                    <a
                        target='_blank'
                        href='https://www.vectra-intl.com/contact-us'
                        //IF THIS TAG USES REACT ROUTER "LINK" COMPONENT, MAKE SURE THAT "style" ATTRIBUTES LIKE ABOVE IS USED HERE.
                        // UNCOMMENT THE FOLLOWING CODE, AFTER MAKING SURE THAT "Link" COMPONENT REPLACES "a" TAG.

                        // style={{color: pathname === ROUTES.ABOUT_US ? BLOOD_ORANGE_COLOR : ''}}

                        //
                    >
                        Contact Us
                    </a>
                </li>
            </ul>

            <ul
                className='header-favorite-account-basket-wrapper'

                //todo: FAVORITE PRODUCTS ( IN THE FIRST CHILD "li" ELEMENT) ARE HIDDEN FOR NOW, THERE IS NO BACKEND SERVICE THAT SUPPORTS THIS.
                //todo: THE FEATURE WILL BE UNBLOCKED IN THE FUTURE. DO NOT REMOVE THE CODE.
            >
                {/* <li>
                    <button aria-label='favorite products' onClick={onFavoriteProduct}>
                        <Heart size={20} />
                        <i className='favorite-products-overlay'>0</i>
                    </button>
                </li> */}
                {/* <li>
                    <button onClick={onAccount} className='header-user-account-wrapper'>
                        {displayName ? <span>{displayName.split(' ')[0]}</span> : <span>Hello</span>}
                        <BsPerson size={20} />
                    </button>
                </li> */}

                <li>
                    <Dropdown
                        toggle={_ => setDropDownOpen(!dropDownOpen)}
                        isOpen={dropDownOpen}
                        direction='down'
                        className='drop-down-wrapper'

                        //
                    >
                        <DropdownToggle className='drop-down-toggler'>
                            {displayName ? <span>{displayName.split(' ')[0]}</span> : <span>Hello</span>}
                            {isUserLoggedIn ? (
                                <img
                                    src={userDataFromLocalSt?.userInfo?.profileImage}
                                    alt=''
                                    className='nav-bar-avatar'
                                />
                            ) : (
                                <BsPerson size={20}/>
                            )}
                            <RxCaretDown size={14}/>
                        </DropdownToggle>
                        <DropdownMenu container='body' className='drop-down-item-wrapper'>
                            <DropdownItem
                                className='drop-down-item'
                                onClick={_ => onHeaderDropDownMenu(ACCOUNT_DROP_DOWN)}>
                                {isUserLoggedIn ? <RxDashboard size={14}/> : <MdOutlineManageAccounts size={14}/>}
                                {isUserLoggedIn ? 'Dashboard' : 'Log in'}
                            </DropdownItem>

                            {isUserLoggedIn && (
                                <>
                                    <DropdownItem
                                        className='drop-down-item'
                                        onClick={_ => onHeaderDropDownMenu(LOGOUT_DROP_DOWN)}>
                                        <HiMiniPower size={14}/> Logout
                                    </DropdownItem>
                                </>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </li>
                <li>
                    <button
                        aria-label='shopping cart'
                        onClick={onShoppingCart}
                        data-shopping-cart-toggler='shopping-cart-toggler'>
                        <PiShoppingCart size={20} data-shopping-cart-toggler='shopping-cart-toggler'/>
                        <i className='shopping-cart-overlay' data-shopping-cart-toggler='shopping-cart-toggler'>
                            {isUserLoggedIn ? store?.cart?.length : shoppingCartItems.length}
                        </i>
                    </button>
                </li>
            </ul>
        </>
    )

    const headerRightSideNavClassName = `hrsn-header-right-side-nav  ${
        smallScreen ? 'hrsnoss-header-right-side-nav-on-small-screen header-right-side-nav-on-small-screens' : ''
    }`

    if (smallScreen) {
        return (
            isBrowserEnv() && (
                <motion.nav
                    className={headerRightSideNavClassName}
                    initial={{x: '-100%'}}
                    animate={{x: 0}}
                    exit={{x: '-100%'}}
                    transition={{type: 'spring', stiffness: 100, damping: 20}}>
                    {navLists}
                </motion.nav>
            )
        )
    }

    return <nav className={headerRightSideNavClassName}>{navLists}</nav>
}

export default NavBar

// ! GET RIDE OF THE FOLLOWING CODE, DEDUCTED BECAUSE OF THE NEW (CHECK OUT INTERNAL NEW .XD DESIGN FILE FOR REF.)
//    <DropdownItem
//                                         className='drop-down-item'
//                                         onClick={_ => onHeaderDropDownMenu(TRAININGS_DROP_DOWN)}>
//                                         <PiChalkboardTeacherThin size={14} /> Trainings
//                                     </DropdownItem>
//                                     <DropdownItem
//                                         className='drop-down-item'
//                                         onClick={_ => onHeaderDropDownMenu(TOOLS_DROP_DOWN)}>
//                                         <Tools size={14} /> Tools
//                                     </DropdownItem>
//                                     <DropdownItem
//                                         className='drop-down-item'
//                                         onClick={_ => onHeaderDropDownMenu(MARKET_PLACE_DROP_DOWN)}>
//                                         <BiShoppingBag size={14} /> Market Place
//                                     </DropdownItem>
