// AUTH API END POINTS

export default {
    //STB
    // authBaseUrl: 'https://vectra-campus-stb-auth-service.azurewebsites.net',
    // subsBaseUrl: 'https://vectra-shop-stb-subs-service.azurewebsites.net',
    // coreBaseUrl: 'https://vectra-campus-stb-core-service.azurewebsites.net',
    // //
    // registerEndpoint: 'https://vectra-campus-stb-auth-service.azurewebsites.net/vectra/auth/signUp/v2',
    // loginEndpoint: 'https://vectra-campus-stb-auth-service.azurewebsites.net/vectra/auth/signin',
    // logoutEndpoint: '/jwt/logout',
    // forgotPasswordEndpoint: 'https://vectra-campus-stb-auth-service.azurewebsites.net/vectras/auth/forgotPassword',
    // refreshEndpoint: 'https://vectra-campus-stb-auth-service.azurewebsites.net/vectra/auth/refreshtoken',

    //PROD
    authBaseUrl: 'https://vectra-campus-prod-auth-service.azurewebsites.net',
    subsBaseUrl: 'https://vectra-campus-prod-subs-service.azurewebsites.net',
    coreBaseUrl: 'https://vectra-campus-prod-core-service.azurewebsites.net',
   // coreBaseUrl: 'http://localhost:8080',
    loginEndpoint: 'https://vectra-campus-prod-auth-service.azurewebsites.net/vectra/auth/signin',
    registerEndpoint: 'https://vectra-campus-prod-auth-service.azurewebsites.net/vectra/auth/signUp/v2',
    refreshEndpoint: 'https://vectra-campus-prod-auth-service.azurewebsites.net/vectra/auth/refreshtokesn',
    logoutEndpoint: '/jwt/logout',
    forgotPasswordEndpoint: 'https://vectra-campus-prod-auth-service.azurewebsites.net/vectras/auth/forgotPassword',

    tokenType: 'Bearer',
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken',
    currentUser: 'currentUser'
}
