import {TOGGLE_SHOPPING_CART} from '../actions/actionNames'

const initialState = false

const shoppingCartModal = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SHOPPING_CART:
            return action.payload.isOpen

        default:
            return state
    }
}

export default shoppingCartModal
