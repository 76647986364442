import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import useJwt from '@src/auth/jwt/useJwt'
import jwtDefaultConfig from '../../@core/auth/jwt/jwtDefaultConfig'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
    const response = await axios.get('/api/users/list/all-data')
    return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
    const response = await axios.get('/api/users/list/data', params)
    return {
        params,
        data: response.data.users,
        totalPages: response.data.total
    }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.authBaseUrl}/vectra/user/getUser`, {userId: id})
        .then(data => data.data.info)
    return res
})

export const updateUser = createAsyncThunk('appUsers/updateUser', async data => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.authBaseUrl}/vectra/user/updateInformation`, data)
        .then(data => data.data)
    return res
})

export const sendMessage = createAsyncThunk('appUsers/sendMessage', async data => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.coreBaseUrl}/academy/sendMessage`, data)
        .then(data => data.data)
    return res
})

export const sendServiceMessage = createAsyncThunk('appUsers/sendServiceMessage', async data => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.authBaseUrl}/vectra/message/serviceMessage`, data)
        .then(data => data.data)
    return res
})
export const updateUserProfileImage = createAsyncThunk('appUsers/updateUserProfileImage', async data => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.authBaseUrl}/vectra/user/uploadProfileImage`, data)
        .then(data => data.data)
    return res
})

export const changePassword = createAsyncThunk('appUsers/changePassword', async data => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.authBaseUrl}/vectra/user/changePassword`, data)
        .then(data => data.data)
    return res
})
export const getExistingCustomerIdByEmail = createAsyncThunk('appUsers/getExistingCustomerIdByEmail', async data => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.subsBaseUrl}/payment/getExistingCustomerIdByEmail`, data)
        .then(data => data.data)
    return res
})

export const createCustomerIdByEmail = createAsyncThunk('appUsers/createCustomerIdByEmail', async data => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.subsBaseUrl}/payment/createTeddCustomer`, data)
        .then(data => data.data)
    return res
})

export const createUserPayments = createAsyncThunk('appUsers/createUserPayments', async data => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.subsBaseUrl}/payment/createPaymentMethod`, data)
        .then(data => data.data)

    return res
})

export const attachPaymentMethod = createAsyncThunk('appUsers/attachPaymentMethod', async data => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.subsBaseUrl}/payment/attachPaymentMethod`, data)
        .then(data => data.data)
    return res
})

export const detachPaymentMethod = createAsyncThunk('appUsers/detachPaymentMethod', async data => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.subsBaseUrl}/payment/detachPaymentMethod`, data)
        .then(data => data.data)
    return res
})

export const getCustomerPaymentMethods = createAsyncThunk('appUsers/getCustomerPaymentMethods', async data => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.subsBaseUrl}/payment/getCustomerPaymentMethods`, data)
        .then(data => data.data.payMethods)
    return res
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, {dispatch, getState}) => {
    await axios.post('/apps/users/add-user', user)
    await dispatch(getData(getState().users.params))
    await dispatch(getAllData())
    return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, {dispatch, getState}) => {
    await axios.delete('/apps/users/delete', {id})
    await dispatch(getData(getState().users.params))
    await dispatch(getAllData())
    return id
})

export const addToCart = createAsyncThunk('appEcommerce/successp', async addCart => {
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/academy/successCheckout?uuid=${addCart.idVal}&cuuid=${addCart.cuuid}`
    )
    return response.data
})

export const makeTeddPaymentIntent = createAsyncThunk('appUsers/makeTeddPaymentIntent', async data => {
    const res = await useJwt.apiCall(`${jwtDefaultConfig.subsBaseUrl}/payment/placeOrder`, data).then(data => data.data)
    return res
})

export const successCheckOut = createAsyncThunk('appEcommerce/successCheckOut', async params => {
    const response = await axios.get(`${jwtDefaultConfig.coreBaseUrl}/academy/successCheckout?cuuid=${params}`)
    return {params, data: response.data}
})

export const getProductAcademy = createAsyncThunk('appEcommerce/getProduct', async product => {
    const response = await axios
        .get(`${jwtDefaultConfig.coreBaseUrl}/academy/getProductDetails?uuid=${product.idVal}&cuuid=${product.cuuid}`)
        .then()
    return response.data
})

export const getUserProductDetails = createAsyncThunk('appEcommerce/getUserProductDetail', async product => {
    const response = await axios
        .get(
            `${jwtDefaultConfig.coreBaseUrl}/academy/getUserProductDetails?uuid=${product.idVal}&cuuid=${product.cuuid}`
        )
        .then()
    return response.data
})

export const getMyProducts = createAsyncThunk('appEcommerce/getMyProducts', async params => {
    const response = await axios.get(`${jwtDefaultConfig.coreBaseUrl}/academy/getMyProducts?cuuid=${params}`)
    return {params, data: response.data.products}
})

export const getMyTools = createAsyncThunk('appEcommerce/getMyTools', async params => {
    const response = await axios.get(`${jwtDefaultConfig.coreBaseUrl}/academy/getMyTools?cuuid=${params}`)
    return {params, data: response.data.products}
})

export const getMyGaps = createAsyncThunk('appEcommerce/getMyGaps', async params => {
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/gap/getListOfGapAnalysis?cuuid=${params.cuuid}&uuid=${params.uuid}`
    )
    return {params, data: response.data.gaps}
})

export const getMyOecdGaps = createAsyncThunk('appEcommerce/getMyOecdGaps', async params => {
    // const response = await axios.get('/apps/ecommerce/products', { params })
    // return { params, data: response.data }
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/gap/getListOfOecdGapAnalysis?cuuid=${params.cuuid}&uuid=${params.uuid}`
    )
    return {params, data: response.data.gaps}
})

export const getMyClientMappingTools = createAsyncThunk('appEcommerce/getMyClientMappingTools', async params => {
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/tool/getListOfClientMappingTools?cuuid=${params.cuuid}&uuid=${params.uuid}`
    )
    return {params, data: response.data.clientMappingTools}
})

export const getMyClientMappingToolDetail = createAsyncThunk(
    'appEcommerce/getMyClientMappingToolDetails',
    async params => {
        const response = await axios.get(
            `${jwtDefaultConfig.coreBaseUrl}/tool/getClientMappingToolDetails?cuuid=${params.cuuid}&uuid=${params.uuid}`
        )
        return response.data
    }
)

export const getMyGapAnalysisDetail = createAsyncThunk('appEcommerce/getMyGapAnalysisDetails', async params => {
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/gap/getUserGapAnalysisDetail?cuuid=${params.cuuid}&uuid=${params.gapId}`
    )
    return response.data
})

export const getUserConflictMineralsGapAnalysisDetail = createAsyncThunk(
    'appEcommerce/getUserConflictMineralsGapAnalysisDetail',
    async params => {
        const response = await axios.get(
            `${jwtDefaultConfig.coreBaseUrl}/gap/getUserConflictMineralsGapAnalysisDetail?cuuid=${params.cuuid}&uuid=${params.uuid}`
        )
        return response.data
    }
)
export const getListOfForcedLaborPreventativeToolDetail = createAsyncThunk(
    'appEcommerce/getListOfForcedLaborPreventativeToolDetail',
    async params => {
        const response = await axios.get(
            `${jwtDefaultConfig.coreBaseUrl}/api/forced-labor/getForcedLaborPreventativeToolDetail?cuuid=${params.cuuid}&uuid=${params.uuid}`
        )
        return response.data
    }
)

export const getWorkplaceSexualHarassmentToolDetail = createAsyncThunk(
    'appEcommerce/getWorkplaceSexualHarassmentToolDetail',
    async params => {
        const response = await axios.get(
            `${jwtDefaultConfig.coreBaseUrl}/api/showwa/getShowwaToolDetail?cuuid=${params.cuuid}&uuid=${params.uuid}`
        )
        return response.data
    }
)
export const norwegianTransparencyActToolDetail = createAsyncThunk(
    'appEcommerce/norwegianTransparencyActToolDetail',
    async params => {
        const response = await axios.get(
            `${jwtDefaultConfig.coreBaseUrl}/api/norwegian-transparency-act/getNtaToolDetail?cuuid=${params.cuuid}&uuid=${params.uuid}`
        )
        return response.data
    }
)
// DELETE
export const deleteNorwegianTransparencyAct = createAsyncThunk(
    'appEcommerce/deleteNorwegianTransparencyAct',
    async params => {
        const response = await axios.get(
            `${jwtDefaultConfig.coreBaseUrl}/api/norwegian-transparency-act/deleteNta?cuuid=${params.cuuid}&uuid=${params.uuid}&UserProduct=${params.UserProduct}`
        )
        return response.data
    }
)

export const deleteWorkplaceSexualHarassmentAct = createAsyncThunk(
    'appEcommerce/deleteWorkplaceSexualHarassmentAct',
    async params => {
        const response = await axios.get(
            `${jwtDefaultConfig.coreBaseUrl}/api/showwa/deleteShowwa?cuuid=${params.cuuid}&uuid=${params.uuid}&UserProduct=${params.UserProduct}`
        )
        return response.data
    }
)

export const deleteGapAnalysis = createAsyncThunk('appEcommerce/deleteGapAnalysis', async params => {
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/gap/deleteGap?cuuid=${params.cuuid}&uuid=${params.uuid}&UserProduct=${params.UserProduct}`
    )
    return response.data
})

export const deleteOECDGapAnalysis = createAsyncThunk('appEcommerce/deleteOECDGapAnalysis', async params => {
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/gap/deleteOecdGap?cuuid=${params.cuuid}&uuid=${params.uuid}&UserProduct=${params.UserProduct}`
    )
    return response.data
})
export const deleteConflictMinerals = createAsyncThunk('appEcommerce/deleteConflictMinerals', async params => {
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/gap/deleteConflictMineralsGap?cuuid=${params.cuuid}&uuid=${params.uuid}&UserProduct=${params.UserProduct}`
    )
    return response.data
})
export const deleteForcedLaborAct = createAsyncThunk('appEcommerce/deleteForcedLaborAct', async params => {
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/api/forced-labor/deleteForcedLaborPreventationTool?cuuid=${params.cuuid}&uuid=${params.uuid}&UserProduct=${params.UserProduct}`
    )
    return response.data
})
// END

export const getListOfConflictMineralsGap = createAsyncThunk(
    'appEcommerce/getListOfConflictMineralsGap',
    async params => {
        const response = await axios.get(
            `${jwtDefaultConfig.coreBaseUrl}/gap/getListOfConflictMineralsGapAnalysis?cuuid=${params.cuuid}&uuid=${params.uuid}`
        )
        return {params, data: response.data.gaps}
    }
)

export const getListOfWorkplaceSexualHarassment = createAsyncThunk(
    'appEcommerce/getListOfWorkplaceSexualHarassment',
    async params => {
        const response = await axios.get(
            `${jwtDefaultConfig.coreBaseUrl}/api/showwa/getShowwaTools?cuuid=${params.cuuid}&uuid=${params.uuid}`
        )
        return {params, data: response.data.showwaTools}
    }
)

export const getListOfNorwegianTransparencyAct = createAsyncThunk(
    'appEcommerce/getListOfNorwegianTransparencyAct',
    async params => {
        const response = await axios.get(
            `${jwtDefaultConfig.coreBaseUrl}/api/norwegian-transparency-act/getNtaTools?cuuid=${params.cuuid}&uuid=${params.uuid}`
        )
        return {params, data: response.data.ntaTools}
    }
)

export const getForcedLaborPreventativeList = createAsyncThunk(
    'appEcommerce/getForcedLaborPreventativeList',
    async params => {
        const response = await axios.get(
            `${jwtDefaultConfig.coreBaseUrl}/api/forced-labor/getListForcedLaborPreventativeTools?cuuid=${params.cuuid}&uuid=${params.uuid}`
        )
        return {params, data: response.data.forcedLaborPreventativeTools}
    }
)

export const getMyOecdGapDetail = createAsyncThunk('appEcommerce/getMyOecdGapDetail', async params => {
    const response = await axios.get(
        `${jwtDefaultConfig.coreBaseUrl}/gap/getUserOecdGapAnalysisDetail?cuuid=${params.cuuid}&uuid=${params.uuid}`
    )
    return response.data
})

export const createGap = createAsyncThunk('appEcommerce/createGap', async data => {
    const res = await useJwt.apiCall(`${jwtDefaultConfig.coreBaseUrl}/gap/createGap`, data).then(data => data.data)
    return res
})
export const createConflictMineralsGap = createAsyncThunk('appEcommerce/createConflictMineralsGap', async data => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.coreBaseUrl}/gap/createConflictMineralsGap`, data)
        .then(data => data.data)
    return res
})

export const createWorkplaceSexualHarassmentAct = createAsyncThunk(
    'appEcommerce/createWorkplaceSexualHarassmentAct',
    async data => {
        const res = await useJwt
            .apiCall(`${jwtDefaultConfig.coreBaseUrl}/api/showwa/createShowwa`, data)
            .then(data => data.data)
        return res
    }
)

export const createNorwegianTransparencyAct = createAsyncThunk(
    'appEcommerce/createNorwegianTransparencyAct',
    async data => {
        const res = await useJwt
            .apiCall(`${jwtDefaultConfig.coreBaseUrl}/api/norwegian-transparency-act/createNta`, data)
            .then(data => data.data)
        return res
    }
)

// UPDATE
export const updateNorwegianTransparencyAct = createAsyncThunk(
    'appEcommerce/updateNorwegianTransparencyAct',
    async data => {
        const response = await useJwt.apiCall(
            `${jwtDefaultConfig.coreBaseUrl}/api/norwegian-transparency-act/updateNta?uuid=${data.uuid}&cuuid=${data.cuuid}`,
            {
                title: data.title,
                description: data.description,
                date: data.date
            }
        )
        return response.data
    }
)

export const updateWorkplaceSexualHarassmentAct = createAsyncThunk(
    'appEcommerce/updateWorkplaceSexualHarassmentAct',
    async data => {
        const response = await useJwt.apiCall(
            `${jwtDefaultConfig.coreBaseUrl}/api/showwa/updateShowwa?uuid=${data.uuid}&cuuid=${data.cuuid}`,
            {
                title: data.title,
                description: data.description,
                date: data.date
            }
        )
        return response.data
    }
)

export const updateGapAnalysis = createAsyncThunk('appEcommerce/updateGapAnalysis', async data => {
    const response = await useJwt.apiCall(
        `${jwtDefaultConfig.coreBaseUrl}/gap/updateGap?uuid=${data.uuid}&cuuid=${data.cuuid}`,
        {
            title: data.title,
            description: data.description,
            date: data.date
        }
    )
    return response.data
})

export const updateOECDGapAnalysis = createAsyncThunk('appEcommerce/updateOECDGapAnalysis', async data => {
    const response = await useJwt.apiCall(
        `${jwtDefaultConfig.coreBaseUrl}/gap/updateOecdGap?uuid=${data.uuid}&cuuid=${data.cuuid}`,
        {
            title: data.title,
            description: data.description,
            date: data.date
        }
    )
    return response.data
})

export const updateConflictMinerals = createAsyncThunk('appEcommerce/updateConflictMinerals', async data => {
    const response = await useJwt.apiCall(
        `${jwtDefaultConfig.coreBaseUrl}/gap/updateConflictMineralsGap?uuid=${data.uuid}&cuuid=${data.cuuid}`,
        {
            title: data.title,
            description: data.description,
            date: data.date
        }
    )
    return response.data
})

export const updateForcedLaborAct = createAsyncThunk('appEcommerce/updateForcedLaborAct', async data => {
    const response = await useJwt.apiCall(
        `${jwtDefaultConfig.coreBaseUrl}/api/forced-labor/updateForcedLaborPreventationTool?uuid=${data.uuid}&cuuid=${data.cuuid}`,
        {
            title: data.title,
            description: data.description,
            date: data.date
        }
    )
    return response.data
})
//  END
export const createForcedLaborPreventativeTool = createAsyncThunk(
    'appEcommerce/createForcedLaborPreventativeTool',
    async data => {
        const res = await useJwt
            .apiCall(`${jwtDefaultConfig.coreBaseUrl}/api/forced-labor/createForcedLaborPreventativeTool`, data)
            .then(data => data.data)
        return res
    }
)
export const createOecdGap = createAsyncThunk('appEcommerce/createOecdGap', async data => {
    const res = await useJwt.apiCall(`${jwtDefaultConfig.coreBaseUrl}/gap/createOecdGap`, data).then(data => data.data)
    return res
})
export const createClientMappingTool = createAsyncThunk('appEcommerce/createClientMappingTool', async data => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.coreBaseUrl}/tool/createClientMappingTool`, data)
        .then(data => data.data)
    return res
})

export const saveToolDetail = createAsyncThunk('appEcommerce/saveToolDetail', async data => {
    const res = await useJwt
        .apiCall(`${jwtDefaultConfig.coreBaseUrl}/tool/saveToolDetail`, data)
        .then(data => data.data)
    return res
})

export const getMyProductsOverview = createAsyncThunk('appEcommerce/getMyProductsOverview', async params => {
    const response = await axios.get(`${jwtDefaultConfig.coreBaseUrl}/academy/getMyProductsOverview?cuuid=${params}`)
    return {params, data: response.data.products}
})

export const downloadPdf = createAsyncThunk('appUsers/downloadPdf', async (product, {rejectWithValue}) => {
    try {
        const url = `${jwtDefaultConfig.coreBaseUrl}/api/download/tool?uuid=${product.idVal}&cuuid=${product.cuuid}`
        const response = await axios.get(url, {
            responseType: 'blob' // Tells Axios to expect a binary response
        })

        const contentDisposition = response.headers['content-disposition']
        let fileName = 'download.pdf' // A default filename if extraction fails
        if (contentDisposition) {
            const matches = contentDisposition.match(/filename="?(.+?)"?($|)/)
            if (matches && matches.length > 1) {
                fileName = matches[1]
            }
        }

        const blob = new Blob([response.data], {type: 'application/pdf'})
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', fileName)

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(downloadUrl)

        return {success: true, fileName}
    } catch (error) {
        console.error('Error downloading PDF:', error)
        return rejectWithValue({error: true, message: 'Could not download PDF.'})
    }
})

export const appUsersSlice = createSlice({
    name: 'appUsers',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        selectedUser: null,
        paymentMethods: [],
        customerId: null,
        myProducts: [],
        myProductDetail: null,
        checkOutSuccess: null,
        mySelectedProductDetail: null
    },
    reducers: {
        setCustomerId(state, action) {
            state.customerId = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAllData.fulfilled, (state, action) => {
                state.allData = action.payload
            })
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.selectedUser = action.payload
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.selectedUser = action.payload
            })
            .addCase(getCustomerPaymentMethods.fulfilled, (state, action) => {
                state.paymentMethods = action.payload
            })
            .addCase(getExistingCustomerIdByEmail.fulfilled, (state, action) => {
                state.customerId = action.payload
            })
            .addCase(getMyProducts.fulfilled, (state, action) => {
                state.myProducts = action.payload.data
            })
            .addCase(getMyTools.fulfilled, (state, action) => {
                state.myTools = action.payload.data
            })
            .addCase(getProductAcademy.fulfilled, (state, action) => {
                state.myProductDetail = action.payload.selectedProduct
            })
            .addCase(getUserProductDetails.fulfilled, (state, action) => {
                state.mySelectedProductDetail = action.payload.selectedProduct
            })
            .addCase(successCheckOut.fulfilled, (state, action) => {
                state.checkOutSuccess = action.payload.data
            })
            .addCase(getMyProductsOverview.fulfilled, (state, action) => {
                state.myProductsOverview = action.payload.data
            })
            .addCase(getMyGaps.fulfilled, (state, action) => {
                state.myGaps = action.payload.data
            })
            .addCase(getListOfConflictMineralsGap.fulfilled, (state, action) => {
                state.getListOfConflictMineralsGap = action.payload.data
            })
            .addCase(getListOfWorkplaceSexualHarassment.fulfilled, (state, action) => {
                state.getListOfWorkplaceSexualHarassment = action.payload.data
            })
            .addCase(getListOfNorwegianTransparencyAct.fulfilled, (state, action) => {
                state.getListOfNorwegianTransparencyAct = action.payload.data
            })
            .addCase(getForcedLaborPreventativeList.fulfilled, (state, action) => {
                state.getForcedLaborPreventativeList = action.payload.data
            })
            .addCase(getMyOecdGaps.fulfilled, (state, action) => {
                state.myOecdGaps = action.payload.data
            })
            .addCase(getMyClientMappingTools.fulfilled, (state, action) => {
                state.myCMTs = action.payload.data
            })
            .addCase(getMyClientMappingToolDetail.fulfilled, (state, action) => {
                state.myCMTs = action.payload.data
            })
            .addCase(getMyGapAnalysisDetail.fulfilled, (state, action) => {
                state.myGap = action.payload.data
            })
            .addCase(getUserConflictMineralsGapAnalysisDetail.fulfilled, (state, action) => {
                state.getUserConflictMineralsGapAnalysisDetail = action.payload.data
            })
            .addCase(getListOfForcedLaborPreventativeToolDetail.fulfilled, (state, action) => {
                state.getListOfForcedLaborPreventativeToolDetail = action.payload.data
            })
            .addCase(getMyOecdGapDetail.fulfilled, (state, action) => {
                state.myGap = action.payload.data
            })
            .addCase(getWorkplaceSexualHarassmentToolDetail.fulfilled, (state, action) => {
                state.getWorkplaceSexualHarassmentToolDetail = action.payload.data
            })
            .addCase(norwegianTransparencyActToolDetail.fulfilled, (state, action) => {
                state.norwegianTransparencyActToolDetail = action.payload.data
            })
    }
})
const {actions} = appUsersSlice

export const {setCustomerId} = actions

export default appUsersSlice.reducer
