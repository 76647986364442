import {useState, useEffect, useRef} from 'react'
import './side-nav-bar.scss'
import HorizontalLineBreak from '../line-break/HorizontalLineBreak'
import useInnerWidth from '../../../../learning_platform/utils/hooks/useInnerWidth'
import {sentenceCase} from 'change-case'
import {PiNotebookDuotone} from 'react-icons/pi'
import {BiCategory} from 'react-icons/bi'
import {MdOutlinePerson4, MdOutlineKeyboardArrowDown} from 'react-icons/md'
import {HiOutlineLockClosed, HiOutlineShoppingBag} from 'react-icons/hi2'
import {BsCode, BsCreditCard2Back, BsFileCodeFill, BsGift, BsListStars} from 'react-icons/bs'
import {VscPackage} from 'react-icons/vsc'
import {IoSettingsOutline} from 'react-icons/io5'
import {IoMdLogOut} from 'react-icons/io'
import {useNavigate, useLocation} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {handleLogout as logUserOut} from '../../../../redux/authentication'
import {LiaChalkboardTeacherSolid, LiaUserLockSolid} from 'react-icons/lia'
import {TbMessageQuestion, TbArrowGuide} from 'react-icons/tb'
import resetShoppingCart from '../../../../actions/resetShoppingCard'
import {GrUserAdmin} from 'react-icons/gr'
import {getUserData} from '../../../../utility/Utils'

export const SIDENAVBAR_TABS = {
    TRAININGS: 'TRAININGS',
    TOOLS: 'TOOLS',
    SHOP: 'SHOP',
    PERSONAL_INFO: 'PERSONAL_INFO',
    LOGIN_AND_SECURITY: 'LOGIN_AND_SECURITY',
    PAYMENTS: 'PAYMENTS',
    ORDERS: 'ORDERS',
    SETTINGS: 'SETTINGS',
    LOGOUT: 'LOGOUT',
    ASK_OUR_EXPERT: 'ASK_OUR_EXPERT',
    CONSULTANCY: 'CONSULTANCY',
    COACHING: 'COACHING',
    REDEEM_A_CODE: 'REDEEM_A_CODE',
    // THE FOLLOWING TAB ITEMS ARE ONLY VISIBLE IF THE ACCESS PRIVILEGE IS ADMIN OR USER AND ADMIN
    // THEY ARE NOT VISIBLE TO USER
    ADMIN_DASHBOARD: 'ADMIN_DASHBOARD',
    USER_ADMIN_DASHBOARD: 'USER_ADMIN_DASHBOARD'
}

const getIconAsComponent = name => {
    if (!name?.trim()) return null
    return {
        [SIDENAVBAR_TABS.TRAININGS]: <PiNotebookDuotone size={16} />,
        [SIDENAVBAR_TABS.TOOLS]: <BiCategory size={16} />,
        [SIDENAVBAR_TABS.SHOP]: null,
        [SIDENAVBAR_TABS.PERSONAL_INFO]: <MdOutlinePerson4 size={16} />,
        [SIDENAVBAR_TABS.LOGIN_AND_SECURITY]: <HiOutlineLockClosed size={16} />,
        [SIDENAVBAR_TABS.PAYMENTS]: <BsCreditCard2Back size={16} />,
        [SIDENAVBAR_TABS.ORDERS]: <VscPackage size={16} />,
        [SIDENAVBAR_TABS.SETTINGS]: <IoSettingsOutline size={16} />,
        [SIDENAVBAR_TABS.ADMIN_DASHBOARD]: <GrUserAdmin size={16} />
        // [SIDENAVBAR_TABS.USER_ADMIN_DASHBOARD]: <LiaUserLockSolid size={16} />
    }[name]
}
function SideNavBar() {
    const user = getUserData()

    const menuBtnRef = useRef()
    const navigate = useNavigate()
    const location = useLocation()

    const dispatch = useDispatch()
    const currentInnerWidth = useInnerWidth()
    const [currentlyActiveTab, setCurrentlyActiveTab] = useState(SIDENAVBAR_TABS.TRAININGS)
    const [isMenuShown, setIsMenuShown] = useState(false)
    const [toggleToolSubmenu, setToggleToolSubmenu] = useState(false)

    useEffect(_ => {
        const onTouchOutisde = evt => {
            const menuParent = menuBtnRef.current
            if (menuParent && !menuParent.contains(evt.target)) {
                setIsMenuShown(false)
            }
        }
        if (currentInnerWidth <= 1000) {
            document.addEventListener('click', onTouchOutisde)
        }
        return _ => {
            document.removeEventListener('click', onTouchOutisde)
        }
    }, [])

    useEffect(
        _ => {
            const {pathname} = location
            if (pathname === '/apps/vectra-tools') setCurrentlyActiveTab(SIDENAVBAR_TABS.TOOLS)
            if (pathname === '/apps/campus') setCurrentlyActiveTab(SIDENAVBAR_TABS.TRAININGS)
            if (pathname === '/apps/ecommerce/shop') setCurrentlyActiveTab(SIDENAVBAR_TABS.SHOP)
            if (pathname === '/user/payments') setCurrentlyActiveTab(SIDENAVBAR_TABS.PAYMENTS)
            if (pathname === '/user/orders') setCurrentlyActiveTab(SIDENAVBAR_TABS.ORDERS)
            if (pathname === '/user/settings') setCurrentlyActiveTab(SIDENAVBAR_TABS.SETTINGS)
            if (pathname === '/apps/consultancy') setCurrentlyActiveTab(SIDENAVBAR_TABS.CONSULTANCY)
            if (pathname === '/apps/ask-our-expert') setCurrentlyActiveTab(SIDENAVBAR_TABS.ASK_OUR_EXPERT)
            if (pathname === '/apps/coaching') setCurrentlyActiveTab(SIDENAVBAR_TABS.COACHING)
            if (pathname === '/apps/redeem-a-code') setCurrentlyActiveTab(SIDENAVBAR_TABS.REDEEM_A_CODE)

            // FOR ADMIN AND USER ADMIN
            if (pathname === '/apps/admin/dashboard') setCurrentlyActiveTab(SIDENAVBAR_TABS.ADMIN_DASHBOARD)
            // if (pathname === '/apps/user-admin/dashboard') setCurrentlyActiveTab(SIDENAVBAR_TABS.USER_ADMIN_DASHBOARD)
        },
        [location.pathname]
    )
    const onTab = tab => {
        const tabsToHandle = [
            SIDENAVBAR_TABS.TRAININGS,
            SIDENAVBAR_TABS.TOOLS,
            SIDENAVBAR_TABS.SHOP,
            SIDENAVBAR_TABS.PERSONAL_INFO,
            SIDENAVBAR_TABS.LOGIN_AND_SECURITY,
            SIDENAVBAR_TABS.PAYMENTS,
            SIDENAVBAR_TABS.ORDERS,
            SIDENAVBAR_TABS.SETTINGS,
            SIDENAVBAR_TABS.ASK_OUR_EXPERT,
            SIDENAVBAR_TABS.COACHING,
            SIDENAVBAR_TABS.CONSULTANCY,
            SIDENAVBAR_TABS.REDEEM_A_CODE,

            // FOR ADMIN AND USER ADMIN
            SIDENAVBAR_TABS.ADMIN_DASHBOARD
            // SIDENAVBAR_TABS.USER_ADMIN_DASHBOARD
        ]

        if (tabsToHandle.includes(tab)) {
            setCurrentlyActiveTab(tab)
            if (tab === SIDENAVBAR_TABS.TRAININGS) navigate('/apps/campus')
            if (tab === SIDENAVBAR_TABS.TOOLS) {
                navigate('/apps/vectra-tools')
                setToggleToolSubmenu(prev => !prev)
            }
            if (tab === SIDENAVBAR_TABS.SHOP) navigate('/apps/ecommerce/shop')
            if (tab === SIDENAVBAR_TABS.PAYMENTS) navigate('/user/payments')
            if (tab === SIDENAVBAR_TABS.ORDERS) navigate('/user/orders')
            if (tab === SIDENAVBAR_TABS.SETTINGS) navigate('/user-preferences/profile')
            if (tab === SIDENAVBAR_TABS.COACHING) navigate('/apps/coaching')
            if (tab === SIDENAVBAR_TABS.ASK_OUR_EXPERT) navigate('/apps/ask-our-expert')
            if (tab === SIDENAVBAR_TABS.CONSULTANCY) navigate('/apps/consultancy')
            if (tab === SIDENAVBAR_TABS.REDEEM_A_CODE) navigate('/apps/redeem-a-code')

            // FOR ADMIN AND USER ADMIN
            if (tab === SIDENAVBAR_TABS.ADMIN_DASHBOARD) navigate('/apps/admin/dashboard')
            // if (tab === SIDENAVBAR_TABS.USER_ADMIN_DASHBOARD) navigate('/apps/user-admin/dashboard')
        }

        if (tab === SIDENAVBAR_TABS.LOGOUT) {
            setIsMenuShown(false)
            dispatch(logUserOut())
            dispatch(resetShoppingCart())
            navigate('/login')
            window.location.reload() // Remove any pertaining state.
        } else {
            setIsMenuShown(false)
        }
    }

    const sideNavBarListItems = (
        <>
            <li className={` ${currentlyActiveTab === SIDENAVBAR_TABS.TRAININGS ? 'currently-active-tab' : ''}`}>
                <button onClick={_ => onTab(SIDENAVBAR_TABS.TRAININGS)}>
                    <PiNotebookDuotone size={16} /> Trainings
                </button>
            </li>
            <li className={`${currentlyActiveTab === SIDENAVBAR_TABS.TOOLS ? 'currently-active-tab' : ''}`}>
                <button onClick={_ => onTab(SIDENAVBAR_TABS.TOOLS)}>
                    <BiCategory size={16} /> Tools
                </button>
            </li>

            <li className={` ${currentlyActiveTab === SIDENAVBAR_TABS.CONSULTANCY ? 'currently-active-tab' : ''}`}>
                <button onClick={_ => onTab(SIDENAVBAR_TABS.CONSULTANCY)}>
                    <TbArrowGuide size={16} />
                    Consultancy
                </button>
            </li>
            {/*<li className={` ${currentlyActiveTab === SIDENAVBAR_TABS.ASK_OUR_EXPERT ? 'currently-active-tab' : ''}`}>*/}
            {/*    <button onClick={_ => onTab(SIDENAVBAR_TABS.ASK_OUR_EXPERT)}>*/}
            {/*        <TbMessageQuestion size={16} />*/}
            {/*        Ask Our Expert*/}
            {/*    </button>*/}
            {/*</li>*/}
            <li className={` ${currentlyActiveTab === SIDENAVBAR_TABS.COACHING ? 'currently-active-tab' : ''}`}>
                <button onClick={_ => onTab(SIDENAVBAR_TABS.COACHING)}>
                    <LiaChalkboardTeacherSolid size={16} />
                    Coaching
                </button>
            </li>

            <li className={`${currentlyActiveTab === SIDENAVBAR_TABS.SHOP ? 'currently-active-tab' : ''}`}>
                <button onClick={_ => onTab(SIDENAVBAR_TABS.SHOP)}>
                    <HiOutlineShoppingBag size={16} /> Shop
                </button>
            </li>
            <HorizontalLineBreak width={60} />

            <li className={`${currentlyActiveTab === SIDENAVBAR_TABS.PAYMENTS ? 'currently-active-tab' : ''}`}>
                <button onClick={_ => onTab(SIDENAVBAR_TABS.PAYMENTS)}>
                    <BsCreditCard2Back size={16} />
                    Payments
                </button>
            </li>
            <li className={`${currentlyActiveTab === SIDENAVBAR_TABS.REDEEM_A_CODE ? 'currently-active-tab' : ''}`}>
                <button onClick={_ => onTab(SIDENAVBAR_TABS.REDEEM_A_CODE)}>
                    <BsGift size={16} />
                    Redeem a code
                </button>
            </li>
            <li className={`${currentlyActiveTab === SIDENAVBAR_TABS.ORDERS ? 'currently-active-tab' : ''}`}>
                <button onClick={_ => onTab(SIDENAVBAR_TABS.ORDERS)}>
                    <VscPackage size={16} />
                    Orders
                </button>
            </li>
            <HorizontalLineBreak width={60} />
            <li className={`${currentlyActiveTab === SIDENAVBAR_TABS.SETTINGS ? 'currently-active-tab' : ''}`}>
                <button onClick={_ => onTab(SIDENAVBAR_TABS.SETTINGS)}>
                    <IoSettingsOutline size={16} /> Settings
                </button>
            </li>

            {/*{*/}
            {/*    //  user?.userInfo?.roles?.includes('USER ADMIN')*/}
            {/*    // remove 'hard-coded' role later.*/}
            {/*    true && (*/}
            {/*        <li*/}
            {/*            className={`  only-admin-access-tab`}*/}
            {/*            // ONLY VISIBLE TO USER ADMIN*/}
            {/*        >*/}
            {/*            <button onClick={_ => onTab(SIDENAVBAR_TABS.ADMIN_DASHBOARD)}>*/}
            {/*                <GrUserAdmin size={16} /> Admin Dashboard*/}
            {/*            </button>*/}
            {/*        </li>*/}
            {/*    )*/}
            {/*}*/}

            <li className='logout'>
                <button onClick={_ => onTab(SIDENAVBAR_TABS.LOGOUT)}>
                    <IoMdLogOut size={16} /> Logout
                </button>
            </li>
        </>
    )

    if (currentInnerWidth <= 1000) {
        return (
            <div
                className='snb-side-nav-bar-on-small-screen'
                aria-label='select an item from side nav bar'
                ref={menuBtnRef}>
                <button className='currently-select-tab-item' onClick={_ => setIsMenuShown(prev => !prev)}>
                    <div className='tab-item'>
                        {getIconAsComponent(currentlyActiveTab)}
                        <span>{sentenceCase(currentlyActiveTab)}</span>
                    </div>
                    <div className='down-arrow'>
                        <MdOutlineKeyboardArrowDown size={14} />
                    </div>
                </button>
                {isMenuShown && <ul className='snb-side-nav-bar'>{sideNavBarListItems}</ul>}
            </div>
        )
    }
    return <ul className='snb-side-nav-bar'>{sideNavBarListItems}</ul>
}

export default SideNavBar

// TODO:

//   IMPLEMENT THE FOLLOWING IF YOU WANT TO RESTIRCT FEW FEATURES THAT ARE ONLY ACCESSIBLE TO THE SUPER ADMIN. IF THE ACCESS PRIVILEGES OF "ADMIN" AND "USER ADMIN" IS A FLAT HORIZONTAL, THE FOLLOWING TAB IS NOT NECESSARY. */}
//<li
//className={`${
//currentlyActiveTab === SIDENAVBAR_TABS.USER_ADMIN_DASHBOARD ? 'currently-active-tab' : ''
//}`}
//<button onClick={_ => onTab(SIDENAVBAR_TABS.USER_ADMIN_DASHBOARD)}>
//<LiaUserLockSolid size={16} /> User Admin Dashboard
//</button>
//</li>
