import {SELECT_CATEGORY} from '../actions/actionNames'
import {getFromLocal, storeOnLocal} from '../learning_platform/utils/localStorage'
import {CLIENT_SELECTED_CATEGORY} from '../global_constants/fixed_variable_values'

const initialState = getFromLocal(CLIENT_SELECTED_CATEGORY) ?? null

const category = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_CATEGORY:
            const category = action.payload
            storeOnLocal(CLIENT_SELECTED_CATEGORY, category)
            return category

        default:
            return state
    }
}

export default category
