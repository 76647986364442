import {LOAD_PRODUCT_REGION} from '../actions/actionNames'
import {getFromLocal, storeOnLocal} from '../learning_platform/utils/localStorage'
import {PRODUCT_REGION} from '../global_constants/fixed_variable_values'

const initialState = getFromLocal(PRODUCT_REGION) ?? null

const productRegion = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PRODUCT_REGION:
            const {region} = action.payload
            storeOnLocal(PRODUCT_REGION, region)
            return region

        default:
            return state
    }
}

export default productRegion
