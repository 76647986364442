import ReactGA from 'react-ga4'
import {GOOGLE_ANALYTICS_MEASUREMENT_ID} from '../App'
ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID)

/**
 *
 * @param {*} category  "The object that was interacted with (e.g. 'Video')"
 * @param {*} action "The type of interaction (e.g. 'play')"
 * @param {*} label "The label of the object that was interacted with (e.g. 'Trailer') or Useful information about the interaction (e.g. 'Clicked on video')"
 * @param {*} value "Numeric value of the interaction (e.g. 100) "
 */

export const trackUsersBehavior = (category, action, label, value) => {
    ReactGA.event({
        category,
        action,
        label,
        value
    })
}
