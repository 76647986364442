import {REMOVE_SHOPPING_CART_ITEM} from './actionNames'

const removingShoppingCartItem = id => {
    return {
        type: REMOVE_SHOPPING_CART_ITEM,
        payload: {id}
    }
}

export default removingShoppingCartItem
