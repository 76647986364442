import {lazy, useEffect} from 'react'
import {useRoutes, Navigate} from 'react-router-dom'
import BlankLayout from '@layouts/BlankLayout'
import {useLayout} from '@hooks/useLayout'
import {getUserData, getHomeRouteForLoggedInUser} from '../utility/Utils'
import {getRoutes} from './routes'
import ReactGA from 'react-ga4'
import RouteChangeTracker from "./RouteChangeTracker"
const GOOGLE_ANALYTICS_MEASUREMENT_ID = 'G-QQGK4Q98WN'

ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID)

// ** Components
const Error = lazy(() => import('../views/pages/misc/Error'))
const Login = lazy(() => import('../views/pages/authentication/Login'))
const Faqs = lazy(() => import('../tedd-landing/pages/Faqs'))
const TermsAndConditions = lazy(() => import('../tedd-landing/pages/TermsAndConditions'))
const Register = lazy(() => import('../views/pages/authentication/Register'))
const ForgotPassword = lazy(() => import('../views/pages/authentication/ForgotPassword'))
const NotAuthorized = lazy(() => import('../views/pages/misc/NotAuthorized'))
const LandingHome = lazy(() => import('../tedd-landing/pages/Home'))
const TrainingDetail = lazy(() => import('../tedd-landing/pages/TrainingDetail'))
const PartnerProductDetail = lazy(() => import('../tedd-landing/pages/PartnerProductDetail'))
const PartnerRegistrationForm = lazy(() => import('../vectra-shop/pages/dashboard/partner_product/register-partner'))
const TrainingsHome = lazy(_ => import('../tedd-landing/pages/TrainingsHome'))
const ConsultancyPublic = lazy(_ => import('../tedd-landing/pages/Consultancy'))
const ConsultancyServiceDetail = lazy(_ => import('../tedd-landing/pages/ConsultancyDetail'))
const ToolDetail = lazy(() => import('../tedd-landing/pages/ToolDetail'))
const ToolHome = lazy(() => import('../tedd-landing/pages/ToolsHome'))
const Router = () => {
    // useEffect(() => {
    //     ReactGA.pageview(window.location.pathname + window.location.search)
    // }, [])
    // ** Hooks
    const {layout} = useLayout()

    const allRoutes = getRoutes(layout)
    const getHomeRoute = () => {
        const user = getUserData()
        if (user) {
            return getHomeRouteForLoggedInUser(user.role)
        } else {
            return '/login'
        }
    }

    const routes = useRoutes([
        // {
        //     path: '/home',
        //     index: true,
        //     element: <Navigate replace to={getHomeRoute()} />
        // },
        {
            path: '/faqs',
            element: <BlankLayout />,
            children: [{path: '/faqs', element: <Faqs />}]
        },
        {
            path: '/terms-and-conditions',
            element: <BlankLayout />,
            children: [{path: '/terms-and-conditions', element: <TermsAndConditions />}]
        },
        {
            path: '/login',
            element: <BlankLayout />,
            children: [{path: '/login', element: <Login />}]
        },
        {
            path: '/register',
            element: <BlankLayout />,
            children: [{path: '/register', element: <Register />}]
        },
        {
            path: '/forgot-password',
            element: <BlankLayout />,
            children: [{path: '/forgot-password', element: <ForgotPassword />}]
        },
        {
            path: '/auth/not-auth',
            element: <BlankLayout />,
            children: [{path: '/auth/not-auth', element: <NotAuthorized />}]
        },
        {
            path: '*',
            element: <BlankLayout />,
            children: [{path: '*', element: <Error />}]
        },

        {
            element: <BlankLayout />,
            path: '/',
            children: [{path: '/', element: <LandingHome />}]
        },
        {
            path: '/product-detail/',
            element: <BlankLayout />,
            children: [{path: '/product-detail/:product', element: <TrainingDetail />}]
        },
        {
            path: '/partner-product-detail/',
            element: <BlankLayout />,
            children: [{path: '/partner-product-detail/:product', element: <PartnerProductDetail />}]
        },

        {
            path: '/partner-product-registration',
            element: <BlankLayout />,
            children: [{path: '/partner-product-registration', element: <PartnerRegistrationForm />}]
        },
        {
            path: '/all-products',
            element: <BlankLayout />,
            children: [{path: '/all-products', element: <TrainingsHome />}]
        },
        {
            path: '/consultancy',
            element: <BlankLayout />,
            children: [{path: '/consultancy', element: <ConsultancyPublic />}]
        },
        {
            path: '/service-detail/',
            element: <BlankLayout />,
            children: [{path: '/service-detail/:product', element: <ConsultancyServiceDetail />}]
        },
        {
            path: '/tool-detail/',
            element: <BlankLayout />,
            children: [{path: '/tool-detail/:product', element: <ToolDetail />}]
        },
        {
            path: '/tools',
            element: <BlankLayout />,
            children: [{path: '/tools', element: <ToolHome />}]
        },

        ...allRoutes
    ])

    return (
        <>
            <RouteChangeTracker /> {/* Add the tracker here */}
            {routes}
        </>
    )
}

export default Router
