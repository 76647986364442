import mock from '../mock'

export const searchArr = [
  {
    groupTitle: 'Pages',
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: 'Home page',
        isBookmarked: false,
        title: 'Home page',
        icon: 'Home',
        link: '/'
      },
      {
        id: 2,
        target: 'Marketplace',
        isBookmarked: false,
        title: 'Marketplace',
        icon: 'ShoppingCart',
        link: '/'
      },
      {
        id: 4,
        target: 'profile',
        isBookmarked: true,
        title: 'Profile',
        icon: 'User',
        link: '/user-preferences/profile'
      }

    ]
  },
  {
    groupTitle: 'Files',
    searchLimit: 4,
    data: [
      {
        title: 'Documents',
        by: '',
        size: '',
        file: require('@src/assets/images/icons/doc.png').default
      },
      {
        title: 'Workshop Materials',
        by: '',
        size: '',
        file: require('@src/assets/images/icons/doc.png').default
      },
      {
        title: 'Invoices',
        by: '',
        size: '',
        file: require('@src/assets/images/icons/doc.png').default
      },
      {
        title: 'Certificates',
        by: '',
        size: '',
        file: require('@src/assets/images/icons/xls.png').default
      }
    ]
  },
  {
    groupTitle: 'Shop',
    searchLimit: 4,
    data: [
      {
        title: 'My Tools',
        email: '',
        img: require('@src/assets/images/logo/favicon.ico').default,
        date: '01/03/2020'
      },
      {
        title: 'My Workshops',
        email: '',
        img: require('@src/assets/images/logo/favicon.ico').default,
        date: ''
      }

    ]
  }
]

// GET Search Data
mock.onGet('/api/main-search/data').reply(() => {
  return [200, { searchArr }]
})

// GET Search Data & Bookmarks
mock.onGet('/api/bookmarks/data').reply(() => {
  const bookmarks = searchArr[0].data.filter(item => item.isBookmarked)
  const suggestions = searchArr[0].data
  return [200, { suggestions, bookmarks }]
})

// POST Update isBookmarked
mock.onPost('/api/bookmarks/update').reply(config => {
  const { id } = JSON.parse(config.data)

  const obj = searchArr[0].data.find(item => item.id === id)

  Object.assign(obj, { isBookmarked: !obj.isBookmarked })

  return [200]
})
