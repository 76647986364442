import isBrowserEnv from '../isBrowserEnv'
function useClientLoggedIn() {
    if (!isBrowserEnv()) return false
    // User Data

    const userData = localStorage.getItem('userData')

    if (!userData) return false

    // TODO
    // AUTHENTICATE USER AND CHECK IF THE USER'S DATA IS NOT TAMPERED.

    return true
}

export default useClientLoggedIn
