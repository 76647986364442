import {lazy} from 'react'

const EcommerceWishlist = lazy(() => import('../../views/apps/ecommerce/wishlist'))
const PaymentSuccess = lazy(() => import('../../views/apps/ecommerce/checkout/success'))
const GapMain = lazy(() => import('../../tedd/tools/gap/main'))
const ConflictMineralsGapAnalysis = lazy(_ => import('../../tedd/tools/conflictMineralsGapAnalysis/main'))
const ForcedLaborPreventionGapAnalysis = lazy(_ => import('../../tedd/tools/forcedLaborPrevention/main'))

const WorkplaceSexualHarassmentAct = lazy(_ => import('../../tedd/tools/workplaceSexualHarassmentAct/main'))
const DownloadableProduct = lazy(() => import('../../tedd-landing/pages/DownloadableProductDetail'))
const NorwegianTransparencyAct = lazy(_ => import('../../tedd/tools/norwegianTransparencyAct/main'))
const GapDetail = lazy(() => import('../../vectra-shop/pages/dashboard/tools/german_due_diligence_gap_analysis_2/GapAnalysis'))
const CmrtGapAnalysis = lazy(_ => import('../../vectra-shop/pages/dashboard/tools/conflict_minerals_gap_analysis_2/ConflictMineralsGapAnalysis'))
const WorkplaceSexualHarassment = lazy(_ =>
    import('../../vectra-shop/pages/dashboard/tools/workplace_sexual_harassment_act/WorkplaceSexualHarassment')
)
const NorwegianTransparencyActDetail = lazy(_ =>
    import('../../vectra-shop/pages/dashboard/tools/norwegian_transparency_act/NorwegianTransparencyAct')
)
const ForcedLaborPreventionAnalysis = lazy(_ =>
    import('../../vectra-shop/pages/dashboard/tools/forced_labour_preventation_2/ForcedLaborPrevention')
)
const WorkplaceSexualHarassmentActAnalysis = lazy(_ =>
    import('../../vectra-shop/pages/dashboard/tools/workplace_sexual_harassment_act_2/WorkplaceSexualHarassment')
)

const ClientMappingMain = lazy(() => import('../../tedd/tools/clientMapping/main'))
const ClientMappingDetail = lazy(() => import('../../tedd/tools/clientMapping/ClientMappingToolDetail'))
const OecdGapMain = lazy(() => import('../../tedd/tools/OecdGapAnalysisTool/main'))
const OecdGapDetail = lazy(() => import('../../vectra-shop/pages/dashboard/tools/oecd_gap_analysis/OecdGapAnalysis'))

// REDESIGNED INTERNAL PAGES' COMPONENTS (SOURCE: Shop Internal Pages_Web-Tablet-Mobile.xd)
const Trainings = lazy(_ => import('../../vectra-shop/pages/dashboard/trainings/Trainings'))
const TrainingDetail = lazy(_ => import('../../vectra-shop/pages/dashboard/trainings/TrainingDetail'))
const LearningDetail = lazy(_ => import('../../vectra-shop/pages/dashboard/learning/LearningDetails'))
const Tools = lazy(_ => import('../../vectra-shop/pages/dashboard/tools/Tools'))
// const PersonalInfo = lazy(_ => import('../../vectra-shop/pages/dashboard/personal-info/PersonalInfo'))
const LoginAndSecurity = lazy(_ => import('../../vectra-shop/pages/dashboard/login-and-security/LoginAndSecurity'))
const Payments = lazy(_ => import('../../vectra-shop/pages/dashboard/payments/Payments'))
const Orders = lazy(_ => import('../../vectra-shop/pages/dashboard/orders/Orders'))
const Settings = lazy(_ => import('../../vectra-shop/pages/dashboard/settings/Settings'))
const Shop = lazy(_ => import('../../vectra-shop/pages/dashboard/shop/shop'))
const ShopDetail = lazy(_ => import('../../vectra-shop/pages/dashboard/shop/ShopDetail'))
const ConsultancyServiceDetail = lazy(_ => import('../../vectra-shop/pages/dashboard/consultancy/ConsultancyDetail'))
const Checkout = lazy(_ => import('../../vectra-shop/pages/checkout/Checkout'))
const AskOurExpert = lazy(_ => import('../../vectra-shop/pages/dashboard/tools/ask_our_expert/AskOurExpert'))
const Consultancy = lazy(_ => import('../../vectra-shop/pages/dashboard/consultancy/consultancy'))
const Coaching = lazy(_ => import('../../vectra-shop/pages/dashboard/tools/coaching/Coaching'))
const RedeemCode = lazy(_ => import('../../vectra-shop/pages/dashboard/tools/redeemCode/RedeemCode'))
const AdminDashboard = lazy(_ => import('../../vectra-shop/pages/dashboard/tools/admin_dashboard/AdminDashboard'))
const MekongClubTool = lazy(_ => import('../../vectra-shop/pages/dashboard/tools/mekong/MekongTool'))
const AppRoutes = [
    {
        element: <Shop />,
        path: '/apps/ecommerce/shop'
    },
    // {
    //     element: <EcommerceWishlist />,
    //     path: '/apps/ecommerce/wishlist'
    // },

    {
        path: '/apps/ecommerce/product-detail/:product',
        element: <ShopDetail />
    },

    {
        path: '/apps/ecommerce/checkout',
        element: <Checkout />
    },
    {
        path: '/apps/ecommerce/success-checkout',
        element: <PaymentSuccess />
    },

    {
        element: <Settings />,
        path: '/user-preferences/profile'
    },

    {
        path: '/apps/vectra-tools',
        element: <Tools />
    },
    // {
    //     path: '/apps/ask-our-expert',
    //     element: <AskOurExpert />
    // },
    {
        path: '/apps/coaching',
        element: <Coaching />
    },
    {
        path: '/apps/consultancy',
        element: <Consultancy />
    },
    {
        path: '/apps/ecommerce/consultancy-service-detail/:product',
        element: <ConsultancyServiceDetail />
    },
    {
        path: '/apps/redeem-a-code',
        element: <RedeemCode />
    },
    {
        path: '/apps/admin/dashboard',
        element: <AdminDashboard />
    },
    {
        path: '/apps/campus',
        element: <Trainings />
    },

    {
        path: '/apps/campus/training-detail/:product',
        element: <TrainingDetail />
    },
    {
        path: '/apps/campus/learning-detail/:product',
        element: <LearningDetail />
    },
    {
        path: '/apps/tools/gap-analysis/:productId',
        element: <GapMain />
    },
    {
        path: '/apps/tools/conflict-minerals-gap-analysis/:productId',
        element: <ConflictMineralsGapAnalysis />
    },
    {
        path: '/apps/tools/forced-labor-prevention-analysis/:productId',
        element: <ForcedLaborPreventionGapAnalysis />
    },
    {
        path: '/apps/tools/workplace-sexual-harassment-act/:productId',
        element: <WorkplaceSexualHarassmentAct />
    },
    {
        path: '/apps/tools/ebook/:productId',
        element: <DownloadableProduct />
    },
    // NorwegianTransparencyAct
    {
        path: '/apps/tools/norwegian-transparency-act/:productId',
        element: <NorwegianTransparencyAct />
    },
    {
        path: '/apps/tools/partner/mekong-club-tool/:productId',
        element: <MekongClubTool />
    },
    {
        path: '/apps/tools/gap-analysis-detail/:gapId',
        element: <GapDetail />
    },
    {
        path: '/apps/tools/conflict-minerals-gap-analysis-detail/:id',
        element: <CmrtGapAnalysis />
    },
    {
        path: '/apps/tools/workplace_sexual_harassment_act-detail/:id',
        element: <WorkplaceSexualHarassmentActAnalysis />
    },

    {
        path: '/apps/tools/norwegian_transparency_act-detail/:id',
        element: <NorwegianTransparencyActDetail />
    },

    {
        path: '/apps/tools/forced-labor-prevention-analysis-detail/:id',
        element: <ForcedLaborPreventionAnalysis />
    },
    {
        path: '/apps/tools/client-mapping/:productId',
        element: <ClientMappingMain />
    },
    {
        path: '/apps/tools/client-mapping-detail/:cmtId',
        element: <ClientMappingDetail />
    },
    {
        path: '/apps/tools/oecd-gap-analysis/:productId',
        element: <OecdGapMain />
    },
    {
        path: '/apps/tools/oecd-gap-analysis-detail/:oecdGapId',
        element: <OecdGapDetail />
    },

    // {
    //     path: '/user/login-and-security',
    //     element: <LoginAndSecurity />
    // },
    {
        path: '/user/payments',
        element: <Payments />
    },
    {
        path: '/user/orders',
        element: <Orders />
    }
    // {
    //     path: '/user/settings',
    //     element: <Settings />
    // }
]

export default AppRoutes

// Legacy

// Previous Components From Old Codebase
// const EcommerceCheckout = lazy(() => import('../../views/apps/ecommerce/checkout'))
// const MyAcademyDetail = lazy(() => import('../../tedd/academy/pages/AcademyDetail'))
// const EcommerceShop = lazy(() => import('../../views/apps/ecommerce/shop'))
// const EcommerceDetail = lazy(() => import('../../views/apps/ecommerce/detail'))
// const Tools = lazy(() => import('../../tedd/tools/pages'))
// const MyAcademy = lazy(() => import('../../tedd/academy/pages'))
// const Calendar = lazy(() => import('../../views/apps/calendar'))

// {
//     element: <EcommerceShop />,
//     path: '/apps/ecommerce/shop'
// },
// {
//     path: '/apps/ecommerce/product-detail/:product',
//     element: <EcommerceDetail />
// },
// {
//     path: '/apps/ecommerce/checkout',
//     element: <EcommerceCheckout />
// },
// {
//     element: <Profile />,
//     path: '/user-preferences/profile'
// },
// {
//     path: '/apps/vectra-tools',
//     element: <Tools />,
//     meta: {
//         className: 'ecommerce-application'
//     }
// },
// {
//     path: '/apps/campus',
//     element: <MyAcademy />,
//     meta: {
//         className: 'ecommerce-application'
//     }
// },
// {
//     path: '/apps/campus/training-detail/:product',
//     element: <MyAcademyDetail />,
//     meta: {
//         className: 'ecommerce-application'
//     }
// }
