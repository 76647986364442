//! IT IS ALWAYS RECOMMENDED TO CHECK IF THE USER IS LOGGED IN  BY SENDING A JWT TOKEN TO THE SERVER ON THE EVERY REQUEST HEADER AND CHECKING THE DB.
//!! THIS IS  A BAD PRACTICE TO STORE USER DATA IN LOCAL STORAGE. NOT SECURE AT ALL.

import {Ability} from '@casl/ability'
import {initialAbility} from './initialAbility'
import isBrowserEnv from '../../utility/isBrowserEnv'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = isBrowserEnv() ? JSON.parse(localStorage.getItem('userData')) : null
const existingAbility = userData ? userData.ability : null

export default new Ability(existingAbility || initialAbility)
