import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import instance from '../../../tedd/academy/service/api'
import isBrowserEnv from '../../../utility/isBrowserEnv'

export default class JwtService {
    jwtConfig = {...jwtDefaultConfig}
    isAlreadyFetchingAccessToken = false
    subscribers = []

    constructor(jwtOverrideConfig) {
        this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig}

        // Intercept request & set configs to request-header.
        axios.interceptors.request.use(
            config => {
                const accessToken = this.getToken()

                if (accessToken) {
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                }
                return config
            },
            error => Promise.reject(error)
        )

        //todo: Response Interceptor (Re-Written, Check the old code after the end of this block)
        axios.interceptors.response.use(
            response => response,
            async error => {
                const {config, response} = error
                if (response?.status === 401) {
                    const accessToken = this.getToken() // CHECK IF THERE IS AN ACCESS TOKEN IN THE LOCAL STORAGE
                    if (accessToken) {
                        try {
                            const rtResponse = await this.refreshToken() // GET REFRESH TOKEN RESPONSE
                            this.setToken(rtResponse.data.accessToken)
                            this.setRefreshToken(rtResponse.data.refreshToken)
                            this.onAccessTokenFetched(rtResponse.data.accessToken)

                            //  ADD BEARER TOKEN TO HEADER
                            config.headers['Authorization'] = `${this.jwtConfig.tokenType} ${accessToken}`

                            return instance(config) //  RETRY SENDING ANOTHER REQUEST
                        } catch (err) {
                            return err
                        }
                    }
                }
                return Promise.reject(error)
            }
        )
        // !    IN THE FOLLOWING COMMENTED-OUT RESPONSE-INTERCEPTOR-MIDDLEWARE, PARTS OF THE CODE ARE DEAD AND NOT REACHABLE.
        // !    IF A USER IS NOT LOGGED IN, THERE IS ALREADY NO ACCESS TOKEN. THE CODE STARTING FROM "this.refreshToken" ASSUMES THE USER HAS ALREADY
        // !    AN ACCESS TOKEN WHEN THERE IS NONE(REASON: USER IS NOT LOGGED IN OR HAS CLEARED LOCAL STORAGE).
        // !    HENCE, THE LOCAL CASHE RETURNS "NULL". THE RESPONSE INTERCEPTOR IS REWRITTEN ABOVE FOR THIS REASON.

        // // ** Add request/response interceptor
        // axios.interceptors.response.use(
        //     response => response,
        //     error => {
        //       // ** const { config, response: { status } } = error
        //       const { config, response } = error
        //       const originalRequest = config

        //       // ** if (status === 401) {
        //       if (response && response.status === 401) {
        //         if (!this.isAlreadyFetchingAccessToken) {

        //           this.isAlreadyFetchingAccessToken = true
        //           this.refreshToken().then(r => {
        //             this.isAlreadyFetchingAccessToken = false

        //             // ** Update accessToken in localStorage
        //             this.setToken(r.data.accessToken)
        //             this.setRefreshToken(r.data.refreshToken)

        //             this.onAccessTokenFetched(r.data.accessToken)
        //           })
        //         }
        //         const retryOriginalRequest = new Promise(resolve => {
        //           this.addSubscriber(accessToken => {
        //             // ** Make sure to assign accessToken according to your response.
        //             // ** Check: https://pixinvent.ticksy.com/ticket/2413870
        //             // ** Change Authorization header
        //             originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        //             resolve(instance(originalRequest))
        //           })
        //         })
        //         return retryOriginalRequest
        //       }
        //       return Promise.reject(error)
        //     }
        // )
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    getToken() {
        if (!isBrowserEnv()) return
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    getRefreshToken() {
        if (!isBrowserEnv()) return
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }

    setToken(value) {
        if (!isBrowserEnv()) return
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    setRefreshToken(value) {
        if (!isBrowserEnv()) return
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    }

    forgotPassword(...args) {
        return axios.post(this.jwtConfig.forgotPasswordEndpoint, ...args)
    }

    contactUs(value) {
        return axios.post(this.jwtConfig.contactUsEndPoint, value)
    }

    sponsorshipMessageContact(value) {
        return axios.post(this.jwtConfig.sponsorshipMessageEndPoint, value)
    }

    login(...args) {
        return axios.post(this.jwtConfig.loginEndpoint, ...args)
    }

    register(...args) {
        return axios.post(this.jwtConfig.registerEndpoint, ...args)
    }

    apiCall(url, ...args) {
        return axios.post(url, ...args)
    }
    apiCallGeturl(url) {
        return axios.get(url)
    }

    refreshToken() {
        return axios.post(this.jwtConfig.refreshEndpoint, {
            refreshToken: this.getRefreshToken()
        })
    }
}
