import {Outlet} from 'react-router-dom'
import {lazy, useEffect, useState} from 'react'
import {SHOPPING_CART_MODAL_STATE_VAR, SHOPPING_CART_STATE_VAR} from '../../redux/rootReducer'
import {useDispatch, useSelector} from 'react-redux'
import toggleShoppingCart from '../../actions/toggleShoppingCart'
import ShoppingCart from '../../tedd-landing/components/ShoppingCart'
import useScrollToTop from '../../utility/hooks/useScrollToTop'
// import {AnimatePresence} from 'framer-motion/dist/framer-motion'
import {addToCart} from '../../views/apps/ecommerce/store'
import {getUserData, isUserLoggedIn} from '../../utility/Utils'
import isBrowserEnv from '../../utility/isBrowserEnv'

let AnimatePresence

if (isBrowserEnv()) {
    const {AnimatePresence: AnimatePresenceModule} = require('framer-motion/dist/framer-motion')
    AnimatePresence = AnimatePresenceModule
}

const BlankLayout = ({children}) => {
    useScrollToTop()
    const dispatch = useDispatch()
    const isShoppingCartModalOpen = useSelector(state => state[SHOPPING_CART_MODAL_STATE_VAR])
    const shoppingCardItems = useSelector(state => state[SHOPPING_CART_STATE_VAR])
    const [isMounted, setIsMounted] = useState(false)
    const user = getUserData()
    const loggedIn = isUserLoggedIn()

    useEffect(() => {
        setIsMounted(true)
    }, [])
    useEffect(() => {
        if (loggedIn) {
            const addToCartAsync = async item => {
                await dispatch(addToCart({idVal: item.uuid, cuuid: user.userInfo.cuuid}))
            }
            const processShoppingCart = async () => {
                if (shoppingCardItems?.length > 0) {
                    const addToCartPromises = shoppingCardItems.map(addToCartAsync)
                    await Promise.all(addToCartPromises)
                }
            }
            processShoppingCart()
        }
    }, [loggedIn])

    if (!isMounted) {
        return null
    }

    return (
        <>
            <Outlet />
            {children}
            <AnimatePresence>
                {isShoppingCartModalOpen && (
                    <ShoppingCart
                        isShoppingCardDisplayed={true}
                        onCloseShoppingCart={_ => dispatch(toggleShoppingCart(false))}
                    />
                )}
            </AnimatePresence>
        </>
    )
}

export default BlankLayout
