import {useEffect, useState} from 'react'
import './location-aware-product-search.scss'
import {TbCurrentLocation} from 'react-icons/tb'
import {HiSelector} from 'react-icons/hi'
// import {BiSearch} from 'react-icons/bi'
import SearchTool from '../form-components/search-tool/SearchTool'
// import CurrentLocation from '../current-location/CurrentLocation'
import {capitalCase} from 'change-case'
import axios from 'axios'
import Swal from 'sweetalert2'
import {useDispatch, useSelector} from 'react-redux'
import toast from 'react-hot-toast'
import {PRODUCT_REGION_STATE_VAR} from '../../../../redux/rootReducer'

import changeProductRegion from '../../../../actions/changeProductRegion'

function LocationAwareProductSearch() {
    const dispatch = useDispatch()
    const productRegion = useSelector(state => state[PRODUCT_REGION_STATE_VAR])

    const [currentLocation, setCurrentLocation] = useState({
        country: '',
        capital: ''
    })
    const [modalOpen, setModalOpen] = useState(false)
    const [searchedItem, setSearchedItem] = useState('')

    useEffect(() => {
        axios.get('https://ipapi.co/json/').then(response => {
            const data = response.data
            const {city: capital, country_name: country} = data

            setCurrentLocation({
                capital,
                country
            })
        })
    }, [])
    const onCurrentLocation = data => {
        // TODO
        setCurrentLocation({country: capitalCase(data.country), capital: capitalCase(data.capital)})
    }

    const onSearchProduct = (evt, text) => {
        // TODO
        evt.preventDefault()
    }

    const onCloseModal = _ => {
        setModalOpen(false)
    }
    const onChangeProductRegion = async () => {
        Swal.fire({
            title: 'Select Product Region',
            html: `<div style="padding: 20px;border:none;"><select id="productRegion" style="width: 100%; padding: 10px;"><option value="Global">Global</option><option value="home">${currentLocation.country}</option></select></div>`,
            showCancelButton: true,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonColor: '#FF0000',
            confirmButtonColor: '#00FF00',

            customClass: {
                confirmButton: 'btn btn-success mx-2',
                cancelButton: 'btn btn-secondary mx-2'
            },
            buttonsStyling: true,
            onOpen: () => {
                Swal.getContent().querySelector('.swal2-actions').style.display = 'flex'
                Swal.getContent().querySelector('.swal2-actions').style.flexDirection = 'row'
                Swal.getContent().querySelector('.swal2-cancel').style.marginLeft = 'auto'
            },
            preConfirm: () => {
                const productRegion = document.getElementById('productRegion').value
                if (!productRegion) {
                    Swal.showValidationMessage('Please select a product region')
                }
                return {productRegion}
            }
        }).then(result => {
            if (result.isConfirmed) {
                // todo: remove cart items
                const value = result.value.productRegion === 'home' ? currentLocation.country : 'Global'

                dispatch(changeProductRegion(value))
                toast.success(`You have changed product region into ${value}`)
            }
        })
    }

    return (
        <div className='laps-location-aware-product-search'>
            <div className='current-location-wrapper'>
                <button
                    // onClick={_ => {
                    //     setModalOpen(true)
                    //     document.body.style.overflow = 'hidden'
                    // }}
                    onClick={onChangeProductRegion}
                    className='current-location'
                    // Current Location Displayer
                >
                    <TbCurrentLocation size={14} />
                    <span className='current-location-name'>
                        {productRegion ? productRegion : currentLocation.country}
                    </span>
                    <HiSelector size={14} />
                </button>
            </div>
            <SearchTool onSearch={onSearchProduct} />
            {/* {modalOpen && <CurrentLocation onCloseModal={onCloseModal} onCurrentLocation={onCurrentLocation} />} */}
        </div>
    )
}
export default LocationAwareProductSearch
